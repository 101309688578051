import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';

const getMessages = () =>
  firebase
    .firestore()
    .collection('messages')
    .get()
    .then((documentSnapshots) => {
      const tmp = [];
      documentSnapshots.forEach((doc) => {
        tmp.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return tmp;
    });

const addTag = async (tag) => {
  const doc = await firebase.firestore().collection('tags').add(tag);
  return doc.id;
};

const deleteTag = (tagId) =>
  firebase.firestore().collection('tags').doc(tagId).delete();

const deleteFollow = (followId) =>
  firebase.firestore().collection('follows').doc(followId).delete();

const addFollow = (follow) =>
  firebase.firestore().collection('follows').add(follow);

const addMessage = (message) =>
  firebase
    .firestore()
    .collection('messages')
    .add({ ...message, created: firebase.firestore.Timestamp.now() });

export { getMessages, addTag, deleteTag, deleteFollow, addFollow, addMessage };
