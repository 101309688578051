import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import { useSelector } from 'react-redux';

import styles from './Me.module.scss';
import Product from './Product';
import SelectTopic from '../Product/SelectTopic';
import Post from '../Post/Post';

const Me = () => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.user);
  const userProductRelation = useSelector((state) => state.userProducts);
  const withdrawals = useSelector((state) => state.withdrawals);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedUserProductId, setSelectedUserProductId] = useState();
  const likes = useSelector((state) => state.likes);
  const [tabStyles, setTabStyles] = React.useState({});
  const [postStyles, setPostStyles] = React.useState({});

  if (!userInfo.id) history.replace('/');

  const userProductCoins = Object.keys(userProductRelation)
    .filter((userProductId) => {
      const { userId, isWithdrawn, coins } = userProductRelation[userProductId];
      return userId === userInfo.id && !isWithdrawn && coins;
    })
    .map((userProductId) => {
      const { coins } = userProductRelation[userProductId];
      return {
        userProductId,
        coins
      };
    });
  const totalCoins = userProductCoins.reduce((accumlate, { coins }) => {
    return accumlate + (coins || 0);
  }, 0);

  const [columns, setColumns] = React.useState(
    window.innerWidth >= 768 ? 2 : 1
  );

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) setColumns(2);
      else setColumns(1);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setTabStyles({
          position: 'fixed',
          top: 56,
          left: '50%',
          transform: 'translate(-50%)'
        });
        setPostStyles({ marginTop: 68 });
      } else {
        setTabStyles({});
        setPostStyles({});
      }
    });
  }, []);

  return (
    <>
      <div className={styles.me}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${userInfo.imageUrl || '/member.png'})`
          }}
        />
        <div className={styles.tabs} style={tabStyles}>
          <button
            onClick={() => setTabIndex(2)}
            style={{ color: tabIndex === 2 ? 'black' : 'white' }}
          >
            收藏
          </button>
          <button
            onClick={() => setTabIndex(0)}
            style={{ color: tabIndex === 0 ? 'black' : 'white' }}
          >
            代言
          </button>
          <button
            onClick={() => setTabIndex(1)}
            style={{ color: tabIndex === 1 ? 'black' : 'white' }}
          >
            分潤
          </button>
        </div>
        {tabIndex === 2 && (
          <div className={styles.posts} style={postStyles}>
            <div className={styles.posts1}>
              {Object.values(likes)
                .filter((like) => {
                  const { userId } = like;
                  return userId && userId === userInfo.id;
                })
                .filter((_, index) => (columns === 2 ? index % 2 === 0 : true))
                .map((like) => {
                  return like.postId;
                })
                .map((userProductId) => {
                  const userProduct = userProductRelation[userProductId];
                  if (!userProduct) return null;
                  return (
                    <Post
                      key={userProductId}
                      postId={userProductId}
                      post={userProduct}
                      clickDeleteToDislike
                    />
                  );
                })}
            </div>
            {columns === 2 && (
              <div className={styles.posts2}>
                {Object.values(likes)
                  .filter((like) => {
                    const { userId } = like;
                    return userId && userId === userInfo.id;
                  })
                  .filter((_, index) => index % 2 === 1)
                  .map((like) => {
                    return like.postId;
                  })
                  .map((userProductId) => {
                    const userProduct = userProductRelation[userProductId];
                    if (!userProduct) return null;
                    return (
                      <Post
                        key={userProductId}
                        postId={userProductId}
                        post={userProduct}
                        clickDeleteToDislike
                      />
                    );
                  })}
              </div>
            )}
          </div>
        )}
        {tabIndex === 0 && (
          <div className={styles.products} style={postStyles}>
            {Object.keys(userProductRelation)
              .filter((userProductId) => {
                const { userId, brandId } = userProductRelation[userProductId];
                return brandId && userId === userInfo.id;
              })
              .sort((a, b) => {
                return userProductRelation[b].created - userProductRelation[a].created;
              })
              .map((userProductId) => {
                const {
                  brandId,
                  productId,
                  created,
                  updated,
                  videoUrl,
                  code,
                  coins
                } = userProductRelation[userProductId];
                return (
                  <Product
                    userProductId={userProductId}
                    userId={userInfo.id}
                    productId={productId}
                    created={created}
                    updated={updated}
                    videoUrl={videoUrl}
                    code={code}
                    key={productId}
                    brandId={brandId}
                    coins={coins}
                    setSelectedUserProductId={setSelectedUserProductId}
                  />
                );
              })}
          </div>
        )}
        {tabIndex === 1 && (
          <>
            <div className={styles.title}>我的分潤</div>
            <div className={styles.value}>$ {totalCoins}</div>
            <div className={styles.link}>
              {userInfo.bankAccount &&
              userInfo.bankAccountFileName &&
              userInfo.bankCode &&
              userInfo.idCardFileName ? (
                <span
                  onClick={() => {
                    const result = window.confirm('確定要提領所有分潤嗎？');
                    if (!result) return;

                    if (totalCoins >= 100) {
                      const promises = userProductCoins.map(
                        ({ userProductId }) => {
                          return firebase
                            .firestore()
                            .collection('posts')
                            .doc(userProductId)
                            .update({
                              isWithdrawn: true
                            });
                        }
                      );
                      Promise.all(promises)
                        .then(() => {
                          const newWithdrawalKey = firebase
                            .database()
                            .ref()
                            .child('withdrawals')
                            .push().key;
                          return firebase
                            .database()
                            .ref()
                            .child(`withdrawals/${newWithdrawalKey}`)
                            .set({
                              created: Date.now(),
                              userId: userInfo.id,
                              coins: totalCoins,
                              status: '提領中'
                            });
                        })
                        .then(() => {
                          window.alert('提領成功');
                        })
                        .catch(() => {
                          window.alert('提領失敗');
                        });
                    } else {
                      window.alert('分潤不足 100 元，無法提領');
                    }
                  }}
                >
                  提領 <FontAwesomeIcon icon={faHandHoldingUsd} size="1x" />
                </span>
              ) : (
                <span>
                  <Link to={`/setting/${userInfo.id}?section=account`}>
                    設定我的廣告分潤帳戶
                  </Link>{' '}
                  <FontAwesomeIcon icon={faCog} size="1x" />
                </span>
              )}
            </div>
            <div className={styles.records}>
              <div className={styles.record}>
                <div>提領時間</div>
                <div>提領金額</div>
                <div>提領狀態</div>
              </div>
              {Object.values(withdrawals)
                .filter((withdrawal) => withdrawal.userId === userInfo.id)
                .map((withdrawal, index) => {
                  return (
                    <div className={styles.record} key={index}>
                      <div>
                        {new Date(withdrawal.created).toLocaleDateString()}
                      </div>
                      <div>${withdrawal.coins}</div>
                      <div>{withdrawal.status}</div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
      {selectedUserProductId && (
        <SelectTopic
          closeModal={() => setSelectedUserProductId()}
          onSelectTopic={() => setSelectedUserProductId()}
          userProductId={selectedUserProductId}
        />
      )}
    </>
  );
};

export default React.memo(Me);
