import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Modal from '../Modal/Modal';

const UserListModal = (props) => {
  const { userIds, ...rest } = props;
  const users = useSelector((state) => state.users);
  const history = useHistory();

  return (
    <Modal
      body={
        <Table>
          <tbody>
            <tr>
              <th>用戶</th>
            </tr>
            {userIds.map((userId) => {
              const user = users[userId];
              return (
                <tr
                  key={userId}
                  onClick={() => {
                    history.push(`/user/${user.id}`);
                    rest.handleClose();
                  }}
                >
                  <td>
                    <img
                      src={user.imageUrl}
                      width="40"
                      height="40"
                      style={{ objectFit: 'cover', borderRadius: '50%' }}
                    />{' '}
                    {user.displayName}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      }
      hideHeader
      hideFooter
      {...rest}
    />
  );
};

export default React.memo(UserListModal);
