import React, { useRef } from 'react';
import linkifyStr from 'linkifyjs/string';
import queryString from 'query-string';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faTrashAlt,
  faUser,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Compressor from 'compressorjs';
import cx from 'classnames';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { GithubPicker } from 'react-color';

import styles from './User.module.scss';
import Post from '../Post/Post';
import svgVerified from '../../images/verified.svg';
import svgLock from './lock.svg';
import hintJpg from './hint.jpg';
import UserListModal from '../UserListModal/UserListModal';
import {
  addFollow,
  addMessage,
  addTag,
  deleteTag,
  deleteFollow
} from '../../utils/firebase';
import Modal from '../Modal/Modal';
import TagModal from '../TagModal/TagModal';

const User = () => {
  const { id: userId } = useParams();
  const { tab: activeTab } = queryString.parse(useLocation().search, {
    parseNumbers: true
  });
  const user = useSelector((state) => state.users[userId] || {});
  const userProductRelation = useSelector((state) => state.userProducts);
  const userInfo = useSelector((state) => state.user);
  const likes = useSelector((state) => state.likes);
  const follows = useSelector((state) => state.follows);
  const tags = useSelector((state) => state.tags);
  const [tab, setTab] = React.useState(1);
  const history = useHistory();
  const inputRef = useRef();
  const tagInputRef = useRef();
  const [isUserListModalShow, setIsUserListModalShow] = React.useState(false);
  const [isCreateTagModalShow, setIsCreateTagModalShow] = React.useState(false);
  const [isCreateTagHintShow, setIsCreateTagHintShow] = React.useState(false);
  const [isTagModalShow, setIsTagModalShow] = React.useState(false);
  const [tabStyles, setTabStyles] = React.useState({});
  const [postStyles, setPostStyles] = React.useState({});
  const [followId, setFollowId] = React.useState();
  const [showFollowees, setShowFollowees] = React.useState(false);
  const [myFollowees, setMyFollowees] = React.useState([]);
  const [showFollowers, setShowFollowers] = React.useState(false);
  const [myFollowers, setMyFollowers] = React.useState([]);
  const [tagIds, setTagIds] = React.useState([]);
  const [activeTagId, setActiveTagId] = React.useState('');
  const activeTag = tags[activeTagId];
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteTagId, setDeleteTagId] = React.useState();
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [tagEmoji, setTagEmoji] = React.useState('😀');
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [tagColor, setTagColor] = React.useState('#9dc6d8');

  React.useEffect(() => {
    setTab(activeTab || 1);
  }, [activeTab]);

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 640) {
        setTabStyles({
          position: 'fixed',
          top: 56,
          left: '50%',
          transform: 'translate(-50%)'
        });
        setPostStyles({ marginTop: 68 });
      } else {
        setTabStyles({});
        setPostStyles({});
      }
    });
  }, []);

  React.useEffect(() => {
    if (!userInfo.id) return;
    const unsubscribe = firebase
      .firestore()
      .collection('follows')
      .where('follower', '==', userInfo.id)
      .where('followee', '==', userId)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setFollowId(doc.id);
        });
      });
    return unsubscribe;
  }, [userInfo.id, userId]);

  React.useEffect(() => {
    if (!userInfo.id) return;

    firebase
      .firestore()
      .collection('follows')
      .where('follower', '==', userInfo.id)
      .onSnapshot((querySnapshot) => {
        const tmp = [];
        querySnapshot.forEach((doc) => {
          tmp.push(doc.data().followee);
        });
        setMyFollowees(tmp);
      });
  }, [userInfo.id]);

  React.useEffect(() => {
    if (!userInfo.id) return;

    const unsubscribe = firebase
      .firestore()
      .collection('follows')
      .where('followee', '==', userId)
      .onSnapshot((querySnapshot) => {
        const tmp = [];
        querySnapshot.forEach((doc) => {
          tmp.push(doc.data().follower);
        });
        setMyFollowers(tmp);
      });

    return unsubscribe;
  }, [userInfo.id, userId]);

  const userPosts = Object.keys(userProductRelation)
    .filter((id) => userProductRelation[id].userId === user.id)
    .filter((id) => userProductRelation[id].updated)
    .filter((id) => {
      if (tab === 1) return userProductRelation[id].type === 'video';
      if (tab === 2) return userProductRelation[id].type === 'image';
      if (tab === 3) return userProductRelation[id].type === 'post';
      return false;
    })
    .filter((id) => {
      if (userProductRelation[id].userId === userInfo.id) return true;
      if (userProductRelation[id].public) return true;
      const tag = tags[userProductRelation[id].tagId];
      if (!tag) return true;
      if (tag.userIds.indexOf(userInfo.id) > -1) return true;
      return false;
    })
    .filter((id) => {
      if (!activeTagId)
        return (
          !userProductRelation[id].tagId ||
          userProductRelation[id].public ||
          !tags[userProductRelation[id].tagId]
        );
      return userProductRelation[id].tagId === activeTagId;
    })
    .sort(
      (a, b) => userProductRelation[b].created - userProductRelation[a].created
    )
    .map((id) => (
      <Post
        key={id}
        post={userProductRelation[id]}
        postId={id}
        isDeleteDisabled={userInfo.id !== userProductRelation[id].userId}
      />
    ));

  const followingId = Object.keys(follows).find((followId) => {
    const follow = follows[followId];
    return follow.followee === userId && follow.follower === userInfo.id;
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.cover}
          style={{
            backgroundImage: `url(${user.cover})`,
            cursor: userId === userInfo.id ? 'pointer' : 'default'
          }}
          onClick={() => {
            if (userId === userInfo.id) {
              inputRef.current.click();
            }
          }}
        >
          {!user.cover && userId === userInfo.id && '＋自訂背景圖片'}
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={(e) => {
              const file = e.target.files[0];
              if (!file) return;
              new Compressor(file, {
                quality: 0.6,
                success: (result) => {
                  firebase
                    .storage()
                    .ref()
                    .child(`users/${userId}/cover`)
                    .put(result, {
                      cacheControl: 'public,max-age=86400'
                    })
                    .then((snapshot) => {
                      snapshot.ref.getDownloadURL().then((downloadURL) => {
                        firebase.database().ref(`users/${userId}`).update({
                          cover: downloadURL
                        });
                      });
                    });
                },
                error(err) {
                  console.log(err.message);
                }
              });
            }}
          />
        </div>
        <div className={styles.info}>
          <div
            alt="使用者照片"
            className={styles.image}
            style={{
              backgroundImage: `url(${user.imageUrl || '/member.png'})`
            }}
          />
          <div className={styles.name}>
            {user.displayName || '使用者'}{' '}
            {user.verified && <img src={svgVerified} width="20" />}
          </div>
          <div className={styles.email}>{user.email}</div>
          <div className={styles.buttons}>
            {!user.verified && userInfo.verified && (
              <Button
                variant={followingId ? 'secondary' : 'outline-secondary'}
                type="button"
                onClick={() => {
                  if (followingId) {
                    firebase.database().ref(`follows/${followingId}`).remove();
                  } else {
                    const likeRef = firebase.database().ref('follows').push();
                    likeRef.set({
                      follower: userInfo.id,
                      followee: userId
                    });
                  }
                }}
                style={{ marginBottom: 12, fontSize: 12 }}
              >
                {followingId ? '關注中' : '關注'}
              </Button>
            )}
            {user.id === userInfo.id ? (
              <Button
                variant="outline-secondary"
                type="button"
                onClick={() => {
                  setShowFollowees(true);
                }}
                style={{ marginBottom: 12, fontSize: 12 }}
              >
                追蹤的用戶
              </Button>
            ) : (
              <Button
                variant={followId ? 'secondary' : 'outline-secondary'}
                type="button"
                onClick={() => {
                  if (followId) {
                    deleteFollow(followId).then(() => {
                      setFollowId();
                    });
                  } else {
                    addFollow({
                      follower: userInfo.id,
                      followee: userId
                    }).then((docRef) => {
                      setFollowId(docRef.id);
                    });
                  }
                }}
                style={{ marginBottom: 12, fontSize: 12 }}
              >
                {followId ? '追蹤中' : '追蹤'}
              </Button>
            )}
            {user.id === userInfo.id && (
              <Button
                variant="outline-secondary"
                type="button"
                onClick={async () => {
                  if (!userInfo.id) return;
                  const docs = await firebase
                    .firestore()
                    .collection('tags')
                    .where('userIds', 'array-contains', userInfo.id)
                    .get();
                  const tmp = [];
                  docs.forEach((doc) => {
                    tmp.push(doc.id);
                  });
                  setTagIds(tmp);
                  setIsTagModalShow(true);
                }}
                style={{ marginBottom: 12, fontSize: 12 }}
              >
                追蹤的標籤
              </Button>
            )}
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.number}>
            <div className={styles.value}>
              {
                Object.values(likes)
                  .filter((like) => {
                    return (
                      (userProductRelation[like.postId] || {}).userId === userId
                    );
                  })
                  .filter((like) => {
                    return like.userId !== userId;
                  }).length
              }
            </div>
            <div className={styles.key}>人氣</div>
          </div>
          {user.verified ? (
            <div
              className={styles.number}
              onClick={() => {
                if (userId === userInfo.id) {
                  setIsUserListModalShow(true);
                }
              }}
            >
              <div className={styles.value}>
                {
                  Object.values(follows).filter((follow) => {
                    return follow.follower === userId;
                  }).length
                }
              </div>
              <div className={styles.key}>已關注</div>
            </div>
          ) : (
            <div className={styles.number}>
              <div className={styles.value}>
                {
                  Object.values(follows).filter((follow) => {
                    return follow.followee === userId;
                  }).length
                }
              </div>
              <div className={styles.key}>商家關注</div>
            </div>
          )}
          <div
            className={styles.number}
            onClick={() => {
              if (userId === userInfo.id) {
                setShowFollowers(true);
              }
            }}
          >
            <div className={styles.value}>{myFollowers.length}</div>
            <div className={styles.key}>粉絲追蹤</div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.title}>簡介</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: linkifyStr(user.intro || '無', {
                attributes: {
                  rel: 'noopener noreferrer',
                  style: 'text-decoration: underline;'
                },
                nl2br: true
              })
            }}
          />
        </div>

        <div className={styles.tags}>
          <div
            className={cx(
              styles.tag,
              activeTagId === '' && styles.active,
              styles.public
            )}
            onClick={() => {
              setActiveTagId('');
            }}
          >
            公開
          </div>
          {Object.keys(tags)
            .filter((tagId) => tags[tagId].userId === userId)
            .map((tagId) => (
              <div
                className={cx(
                  styles.tag,
                  tagId === activeTagId && styles.active,
                  isDeleting && styles.active
                )}
                key={tagId}
                onClick={() => {
                  if (isDeleting) {
                    setDeleteTagId(tagId);
                  } else {
                    setActiveTagId(tagId);
                  }
                }}
                style={{
                  backgroundColor: tags[tagId].color || 'green',
                  borderLeftColor: tags[tagId].color || 'green'
                }}
              >
                {isDeleting && <FontAwesomeIcon icon={faTimes} />}{' '}
                {tags[tagId].userIds.length > 0 && (
                  <span
                    className="pr-1 mr-1"
                    style={{
                      borderRight: '1px dashed white',
                      fontFamily: 'inherit',
                      fontWeight: 'inherit'
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} className="mr-1" />
                    {tags[tagId].userIds.length}
                  </span>
                )}
                <span style={{ fontSize: 16, verticalAlign: -2 }}>
                  {tags[tagId].emoji}
                </span>{' '}
                {tags[tagId].name}
              </div>
            ))}
          {user.id === userInfo.id && (
            <div
              className={cx(styles.tag, styles.createTag)}
              onClick={() => {
                setIsCreateTagModalShow(true);
              }}
            >
              ＋新增
            </div>
          )}
          {user.id === userInfo.id && (
            <div
              className={cx(styles.tag, styles.createTag)}
              onClick={() => {
                setIsDeleting((bool) => !bool);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </div>
          )}
        </div>
        <div className={styles.tabs} style={tabStyles}>
          <button
            onClick={() => history.replace('?tab=1')}
            style={{ color: tab === 1 ? 'black' : 'white' }}
          >
            影片
          </button>
          <button
            onClick={() => history.replace('?tab=2')}
            style={{ color: tab === 2 ? 'black' : 'white' }}
          >
            圖片
          </button>
          <button
            onClick={() => history.replace('?tab=3')}
            style={{ color: tab === 3 ? 'black' : 'white' }}
          >
            貼文
          </button>
        </div>
        {userInfo.id !== userId &&
          activeTag &&
          activeTag.userIds &&
          !activeTag.userIds.includes(userInfo.id) && (
            <div className={styles.followButton} style={{ marginTop: 24 }}>
              <img src={svgLock} width="24" style={{ marginBottom: 12 }} />
              <Button
                onClick={() => {
                  firebase
                    .firestore()
                    .collection('messages')
                    .where('senderId', '==', userInfo.id)
                    .where('tagId', '==', activeTagId)
                    .get()
                    .then((snapshot) => {
                      if (snapshot.empty) {
                        addMessage({
                          content: `使用者
                            ${
                              userInfo.displayName
                                ? `「${userInfo.displayName}」`
                                : ''
                            }
                        欲申請追蹤您的標籤「${activeTag.name}」`,
                          title: '申請追蹤標籤',
                          receiverIds: [userId],
                          tagId: activeTagId,
                          senderId: userInfo.id
                        });
                      } else {
                        window.alert('您已申請過');
                      }
                    });
                }}
              >
                申請追蹤標籤
              </Button>
            </div>
          )}
        <div className={styles.posts} style={postStyles}>
          {userPosts}
        </div>
      </div>
      <UserListModal
        show={isUserListModalShow}
        userIds={Object.values(follows)
          .filter((follow) => {
            return follow.follower === userId;
          })
          .map((follow) => follow.followee)}
        handleClose={() => setIsUserListModalShow(false)}
      />
      <Modal
        show={isCreateTagModalShow}
        title={
          <>
            新增標籤{' '}
            <span
              style={{
                color: '#a2c198',
                fontSize: 18,
                verticalAlign: 2,
                cursor: 'pointer'
              }}
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                onClick={() => setIsCreateTagHintShow(true)}
              />
            </span>
          </>
        }
        body={
          <Form inline>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setShowColorPicker(false);
                setShowEmojiPicker((bool) => !bool);
              }}
              className="mr-1 my-1"
            >
              {tagEmoji}
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setShowEmojiPicker(false);
                setShowColorPicker((bool) => !bool);
              }}
              className="mr-1 my-1"
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  backgroundColor: tagColor,
                  margin: '4px 0'
                }}
              />
            </Button>
            <div style={{ width: '100%' }}>
              {showEmojiPicker && (
                <Picker
                  onSelect={(emoji) => {
                    setTagEmoji(emoji.native);
                    setShowEmojiPicker(false);
                  }}
                />
              )}
              {showColorPicker && (
                <GithubPicker
                  triangle="hide"
                  colors={[
                    '#9dc6d8',
                    '#00b3ca',
                    '#7dd0b6',
                    '#1d4e89',
                    '#d2b29b',
                    '#e38690',
                    '#f69256',
                    '#ead98b',
                    '#965251',
                    '#c6cccc'
                  ]}
                  onChangeComplete={(color) => {
                    setTagColor(color.hex);
                    setShowColorPicker(false);
                  }}
                />
              )}
            </div>
            <FormControl
              placeholder="請輸入標籤名稱"
              ref={tagInputRef}
              className="my-1"
            />
          </Form>
        }
        handleClose={() => setIsCreateTagModalShow(false)}
        handleClick={async () => {
          if (tagInputRef.current.value.trim().length === 0) {
            window.alert('請輸入標籤名稱');
          } else {
            await addTag({
              userId: userInfo.id,
              name: tagInputRef.current.value.trim(),
              userIds: [],
              emoji: tagEmoji,
              color: tagColor
            });
            setIsCreateTagModalShow(false);
          }
        }}
      />
      <Modal
        show={isCreateTagHintShow}
        body={<img src={hintJpg} width="100%" />}
        handleClose={() => setIsCreateTagHintShow(false)}
        hideHeader
        hideFooter
      />
      <Modal
        show={deleteTagId}
        title="刪除標籤"
        body="若刪除此標籤，被貼上此標籤的貼文將全部自動設為公開喔！"
        handleClose={() => setDeleteTagId()}
        handleClick={async () => {
          await deleteTag(deleteTagId);
          setDeleteTagId();
        }}
      />
      <TagModal
        show={isTagModalShow}
        tagIds={tagIds}
        handleClose={() => setIsTagModalShow(false)}
      />
      <UserListModal
        show={showFollowees}
        userIds={myFollowees}
        handleClose={() => setShowFollowees(false)}
      />
      <UserListModal
        show={showFollowers}
        userIds={myFollowers}
        handleClose={() => setShowFollowers(false)}
      />
    </>
  );
};

User.propTypes = {};

User.defaultProps = {};

export default React.memo(User);
