import React from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';

import styles from './Inbox.module.scss';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Inbox = () => {
  const { id: userId } = useParams();
  const [messages, setMessages] = React.useState([]);
  const [selectedMessage, setSelectedMessage] = React.useState();
  const users = useSelector((state) => state.users);
  const tags = useSelector((state) => state.tags);

  React.useEffect(() => {
    const tmp = [];
    firebase
      .firestore()
      .collection('messages')
      .orderBy('created')
      .get()
      .then((documentSnapshots) => {
        documentSnapshots.forEach((doc) => {
          const data = doc.data();
          if (!data.receiverIds || data.receiverIds.includes(userId)) {
            tmp.push({
              id: doc.id,
              ...data
            });
          }
        });
        setMessages(tmp);
      });
    firebase
      .firestore()
      .collection('messages')
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'modified') {
            setMessages((messages) =>
              messages.map((message) => {
                if (message.id === change.doc.id) {
                  return {
                    id: message.id,
                    ...change.doc.data()
                  };
                }
                return message;
              })
            );
          }
        });
      });
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <table>
          <tbody>
            {messages.map((message) => {
              const style = {};
              const isRead =
                message.readerIds && message.readerIds.includes(userId);
              if (!isRead) {
                style.fontWeight = 'bold';
              }
              return (
                <tr
                  key={message.id}
                  style={style}
                  onClick={() => {
                    if (!isRead) {
                      firebase
                        .firestore()
                        .collection('messages')
                        .doc(message.id)
                        .update({
                          readerIds: message.readerIds
                            ? [...message.readerIds, userId]
                            : [userId]
                        });
                    }
                    setSelectedMessage(message);
                  }}
                >
                  <td>
                    <img
                      src={
                        message.imageUrl ||
                        users[message.senderId]?.imageUrl
                      }
                    />
                  </td>
                  <td>
                    <div>{message.title}</div>
                    <div>{message.content}</div>
                  </td>
                  <td>{message.created.toDate().toLocaleDateString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {selectedMessage && (
        <div
          className={styles.modalWrapper}
          onClick={() => {
            setSelectedMessage();
          }}
        >
          <div className={styles.modal}>
            <img src={selectedMessage.imageUrl} />
            <div>{selectedMessage.title}</div>
            {selectedMessage.senderId && (
              <>
                {users[selectedMessage.senderId].imageUrl && (
                  <img
                    src={users[selectedMessage.senderId].imageUrl}
                    style={{ width: 48, height: 48, borderRadius: 24 }}
                  />
                )}
                {users[selectedMessage.senderId].name && (
                  <div>{users[selectedMessage.senderId].name}</div>
                )}
                {users[selectedMessage.senderId].email && (
                  <div>{users[selectedMessage.senderId].email}</div>
                )}
              </>
            )}
            <div>{selectedMessage.content}</div>
            {selectedMessage.tagId &&
              tags[selectedMessage.tagId] &&
              !tags[selectedMessage.tagId].userIds.includes(
                selectedMessage.senderId
              ) && (
                <div className={styles.buttons}>
                  <Button
                    onClick={() => {
                      firebase
                        .firestore()
                        .collection('tags')
                        .doc(selectedMessage.tagId)
                        .update({
                          userIds: firebase.firestore.FieldValue.arrayUnion(
                            selectedMessage.senderId
                          )
                        });
                    }}
                  >
                    同意
                  </Button>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};

Inbox.propTypes = {};

Inbox.defaultProps = {};

export default React.memo(Inbox);
