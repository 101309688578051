const getYoutubeEmbedUrl = (originalUrl = '') => {
  const result = originalUrl.match(
    /youtu\.be\/(\S{11})|www\.youtube\.com\/watch\?v=(\S{11})/
  );
  const v = result && (result[1] || result[2]);
  return (v && `https://www.youtube.com/embed/${v}`) || originalUrl;
};

const getMessengerUrl = (facebookUrl = '') => {
  const result = facebookUrl.match(/www\.facebook\.com\/(\S+)\//);
  const v = result && result[1];
  return (v && `https://m.me/${v}`) || facebookUrl;
};

export { getYoutubeEmbedUrl, getMessengerUrl };
