import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, Input, Tabs, Dialog } from 'element-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudDownloadAlt,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import cx from 'classnames';

import styles from './Search.module.scss';

const typeOptions = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '影片',
    value: 'video'
  },
  {
    label: '圖片',
    value: 'image'
  },
  {
    label: '貼文',
    value: 'post'
  }
];

const orderOptions = [
  {
    label: '從新到舊',
    value: 'desc'
  },
  {
    label: '從舊到新',
    value: 'asc'
  }
];

const Search = () => {
  const userProducts = useSelector((state) => state.userProducts);
  const users = useSelector((state) => state.users);
  const brands = useSelector((state) => state.brands);
  const products = useSelector((state) => state.products);
  const withdrawals = useSelector((state) => state.withdrawals);
  const [isLogin, setIsLogin] = useState(
    !!window.localStorage.getItem('isLogin')
  );
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [activeTabName, setActiveTabName] = useState('userProducts');

  const [type, setType] = useState('all');
  const [order, setOrder] = useState('desc');
  const [title, setTitle] = useState('');
  const [user, setUser] = useState('');
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedPost, setSelectedPost] = useState();

  if (isLogin)
    return (
      <div>
        <div className={styles.tabs}>
          <Tabs
            activeName={activeTabName}
            onTabClick={(tab) => setActiveTabName(tab.props.name)}
          >
            <Tabs.Pane label="代言列表" name="userProducts"></Tabs.Pane>
            <Tabs.Pane label="提領紀錄" name="withdrawals"></Tabs.Pane>
          </Tabs>
        </div>
        {activeTabName === 'userProducts' && (
          <>
            <div className={styles.filters}>
              <Select value={type} onChange={setType}>
                {typeOptions.map((el) => {
                  return (
                    <Select.Option
                      key={el.value}
                      label={el.label}
                      value={el.value}
                    />
                  );
                })}
              </Select>
              <Select value={order} onChange={setOrder}>
                {orderOptions.map((el) => {
                  return (
                    <Select.Option
                      key={el.value}
                      label={el.label}
                      value={el.value}
                    />
                  );
                })}
              </Select>
              <Input
                type="text"
                value={title}
                onChange={setTitle}
                placeholder="搜尋名稱"
              />
              <Input
                type="text"
                value={user}
                onChange={setUser}
                placeholder="搜尋作者"
              />
            </div>
            <table className={styles.table}>
              <tr>
                <th>ID</th>
                <th>標題</th>
                <th>作者</th>
                <th>類型</th>
                <th>觀看數</th>
                <th>觸及次數</th>
                <th>驗證檔</th>
                <th>發布日期</th>
                <th>分潤</th>
                <th>提領狀態</th>
                <th>店家</th>
                <th>商品</th>
              </tr>
              {Object.keys(userProducts)
                .map((userProductId) => ({
                  id: userProductId,
                  ...userProducts[userProductId]
                }))
                .filter((userProduct) => {
                  return (
                    userProduct.updated &&
                    (type === 'all' || type === userProduct.type) &&
                    (!title || (userProduct.title || '').includes(title)) &&
                    (!user ||
                      (users[userProduct.userId] &&
                        users[userProduct.userId].displayName.includes(user)))
                  );
                })
                .sort((a, b) =>
                  order === 'asc'
                    ? (a.updated || 0) - (b.updated || 0)
                    : (b.updated || 0) - (a.updated || 0)
                )
                .map((userProduct, index) => (
                  <tr key={index}>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setDialogVisible(true);
                        setSelectedPost(userProduct);
                      }}
                    >
                      {userProduct.id}
                    </td>
                    <td>{userProduct.title}</td>
                    <td>
                      {users[userProduct.userId] &&
                        users[userProduct.userId].displayName}
                    </td>
                    <td>{userProduct.type}</td>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const view = window.prompt('請輸入觀看數');
                        if (!view) return;
                        firebase
                          .firestore()
                          .collection('posts')
                          .doc(userProduct.id)
                          .update({
                            view: Number(view)
                          });
                      }}
                    >
                      {userProduct.view || (
                        <FontAwesomeIcon icon={faPencilAlt} color="#dcdcdc" />
                      )}
                    </td>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const reach = window.prompt('請輸入觸及次數');
                        if (!reach) return;
                        firebase
                          .firestore()
                          .collection('posts')
                          .doc(userProduct.id)
                          .update({
                            reach: Number(reach)
                          });
                      }}
                    >
                      {userProduct.reach || (
                        <FontAwesomeIcon icon={faPencilAlt} color="#dcdcdc" />
                      )}
                    </td>
                    <td>
                      {userProduct.verificationVideoUrl && (
                        <a download href={userProduct.verificationVideoUrl}>
                          <FontAwesomeIcon icon={faCloudDownloadAlt} />
                        </a>
                      )}
                    </td>
                    <td>
                      {new Date(userProduct.updated).toLocaleDateString()}
                    </td>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const coins = window.prompt('請輸入分潤');
                        if (!coins) return;
                        firebase
                          .firestore()
                          .collection('posts')
                          .doc(userProduct.id)
                          .update({
                            coins: Number(coins)
                          });
                      }}
                    >
                      {typeof userProduct.coins === 'number' ? (
                        userProduct.coins
                      ) : (
                        <FontAwesomeIcon icon={faPencilAlt} color="#dcdcdc" />
                      )}
                    </td>
                    <td>{userProduct.isWithdrawn && 'V'}</td>
                    <td>
                      {brands[userProduct.brandId] &&
                        brands[userProduct.brandId].title}
                    </td>
                    <td>
                      {products[userProduct.productId] &&
                        products[userProduct.productId].name}
                    </td>
                  </tr>
                ))}
            </table>
            <Dialog
              title={selectedPost && selectedPost.id}
              size="tiny"
              visible={dialogVisible}
              onCancel={() => {
                setDialogVisible(false);
                setSelectedPost();
              }}
              lockScroll={false}
            >
              {selectedPost && (
                <Dialog.Body>
                  <div>標題：{selectedPost.title}</div>
                  <div>
                    作者：
                    {users[selectedPost.userId] &&
                      users[selectedPost.userId].displayName}
                  </div>
                  <div>
                    店家：
                    {brands[selectedPost.brandId] &&
                      brands[selectedPost.brandId].title}
                  </div>
                  <div>
                    商品：
                    {products[selectedPost.productId] &&
                      products[selectedPost.productId].name}
                  </div>
                </Dialog.Body>
              )}
            </Dialog>
          </>
        )}
        {activeTabName === 'withdrawals' && (
          <table className={cx(styles.table, styles.withdrawals)}>
            <tr>
              <th>ID</th>
              <th>創作者</th>
              <th>提領金額</th>
              <th>實際應轉帳金額</th>
              <th>申請提領日期</th>
              <th>提領狀態</th>
            </tr>
            {Object.keys(withdrawals)
              .map((withdrawalId) => ({
                id: withdrawalId,
                ...withdrawals[withdrawalId]
              }))
              .sort((a, b) => b.created - a.created)
              .map((withdrawal, index) => (
                <tr key={index}>
                  <td>{withdrawal.id}</td>
                  <td>
                    {users[withdrawal.userId] &&
                      users[withdrawal.userId].displayName}
                  </td>
                  <td>{withdrawal.coins}</td>
                  <td>{Math.floor(withdrawal.coins * 0.95) - 20}</td>
                  <td>{new Date(withdrawal.created).toLocaleDateString()}</td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const sure = window.confirm('確定要將狀態改為已提領？');
                      if (!sure) return;
                      firebase
                        .database()
                        .ref(`withdrawals/${withdrawal.id}`)
                        .update({
                          status: '已提領'
                        });
                    }}
                  >
                    {withdrawal.status}
                  </td>
                </tr>
              ))}
          </table>
        )}
      </div>
    );
  return (
    <div>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        style={{ border: '1px solid gray' }}
      />
      <br />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ border: '1px solid gray' }}
      />
      <br />
      <button
        onClick={() => {
          if (username === 'civimender' && password === 'civimender5566')
            window.localStorage.setItem('isLogin', true);
          setIsLogin(true);
        }}
      >
        登入
      </button>
    </div>
  );
};

Search.propTypes = {};

Search.defaultProps = {};

export default React.memo(Search);
