import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router';

import Modal from '../Modal/Modal';

const TagModal = (props) => {
  const { tagIds, ...rest } = props;
  const tags = useSelector((state) => state.tags);
  const users = useSelector((state) => state.users);
  const history = useHistory();

  const list = tagIds.map((tagId) => {
    const tag = tags[tagId];
    const user = users[tag.userId];
    return {
      tagId: tagId,
      tagName: tag.name,
      userId: user.id,
      userName: user.displayName,
      userImage: user.imageUrl
    };
  });

  return (
    <Modal
      body={
        <Table>
          <tbody>
            <tr>
              <th>標籤</th>
              <th>用戶</th>
            </tr>
            {list.map((item) => (
              <tr
                key={item.tagId}
                onClick={() => {
                  history.push(`/user/${item.userId}`);
                  rest.handleClose();
                }}
              >
                <td style={{ verticalAlign: 'middle' }}>{item.tagName}</td>
                <td>
                  <img
                    src={item.userImage}
                    width="40"
                    height="40"
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                  />{' '}
                  {item.userName}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      }
      hideHeader
      hideFooter
      {...rest}
    />
  );
};

export default TagModal;
