import React from 'react';

import styles from './YoutubeUrlInputModal.module.scss';

const YoutubeUrlInputModal = (props) => {
  const { closeModal, onInputUrl } = props;
  const [inputValue, setInputValue] = React.useState('');
  return (
    <div
      className={styles.modalWrapper}
      onClick={(e) => {
        if (e.target === e.currentTarget) closeModal();
      }}
    >
      <div className={styles.modal}>
        <div className={styles.title}>輸入 YouTube 內嵌網址</div>
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button
          className={styles.topicFree}
          onClick={() => {
            onInputUrl(inputValue);
          }}
        >
          確定
        </button>
      </div>
    </div>
  );
};

YoutubeUrlInputModal.propTypes = {};

YoutubeUrlInputModal.defaultProps = {};

export default React.memo(YoutubeUrlInputModal);
