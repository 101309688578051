import React from 'react';
import lottie from 'lottie-web';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import qs from 'query-string';
import ReactLoading from 'react-loading';
import { Waypoint } from 'react-waypoint';
import { useHistory, useLocation } from 'react-router-dom';

import styles from './NewVideos.module.scss';
import Post from '../Post/Post';
import animationData from './data2.json';
import { useSelector, useDispatch } from 'react-redux';
import { setAnimationPlayed } from '../../redux/actions/animation';
import fire from '../../images/fire.png';

const TYPES = {
  1: 'video',
  2: 'image',
  3: 'post'
};

const postSliderSettings = {
  infinite: true,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000
};

function usePrevious(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}

const NewVideos = () => {
  const scrollYRef = React.useRef(0);
  const dispatch = useDispatch();
  const show = useSelector((state) => state.guide.show);
  const animationPlayed = useSelector((state) => state.animation.played);
  const banners = useSelector((state) => state.banners);
  const users = useSelector((state) => state.users);
  const likes = useSelector((state) => state.likes);
  const userProductRelation = useSelector((state) => state.userProducts);
  const [numberOfPosts, setNumberOfPosts] = React.useState(6);
  const brands = useSelector((state) => state.brands);
  const tags = useSelector((state) => state.tags);
  const userInfo = useSelector((state) => state.user);
  const [tab, setTab] = React.useState(0);
  const prevTab = usePrevious(tab);
  const [showVideo, setShowVideo] = React.useState(false);
  const history = useHistory();
  const videoRef = React.useRef();
  const [showTabsOnly, setShowTabsOnly] = React.useState(false);
  const { tab: activeTab } = qs.parse(useLocation().search, {
    parseNumbers: true
  });
  const [columns, setColumns] = React.useState(
    window.innerWidth >= 768 ? 2 : 1
  );

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) setColumns(2);
      else setColumns(1);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    setTab(activeTab || 0);
  }, [activeTab]);

  React.useEffect(() => {
    if (window.localStorage.getItem('visited')) {
      if (animationPlayed) {
        setShowVideo(true);
      } else {
        const anim = lottie.loadAnimation({
          container: videoRef.current,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData
        });
        anim.addEventListener('complete', () => {
          anim.destroy();
          setShowVideo(true);
          dispatch(setAnimationPlayed());
        });
      }
    }
  }, [show]);

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > scrollYRef.current) {
        if (window.scrollY > 310) {
          setShowTabsOnly(true);
        } else {
          setShowTabsOnly(false);
        }
      } else {
        setShowTabsOnly(false);
      }
      scrollYRef.current = window.scrollY;
    });
  }, []);

  const posts = Object.keys(userProductRelation)
    .filter((postId) => {
      if (tab === 0)
        return (
          ['video', 'image', 'post'].indexOf(userProductRelation[postId].type) >
          -1
        );
      else return userProductRelation[postId].type === TYPES[tab];
    })
    .filter((postId) => {
      if (userProductRelation[postId].public) return true;
      const tag = tags[userProductRelation[postId].tagId];
      if (!tag || !tag.userIds) return true;
      if (tag.userIds.indexOf(userInfo.id) > -1) return true;
      return false;
    })
    .sort(
      (a, b) => userProductRelation[b].updated - userProductRelation[a].updated
    )
    .slice(0, numberOfPosts)
    .map((postId) => ({ ...userProductRelation[postId], id: postId }));

  React.useEffect(() => {
    if (prevTab !== undefined && prevTab !== tab) {
      setNumberOfPosts(6);
    }
  }, [tab]);

  const promteBanners = Object.entries(banners)
    .filter(([, banner]) => {
      return banner.promote;
    })
    .map(([bannerId, banner]) => {
      return {
        ...banner,
        id: bannerId
      };
    });

  const topUsers = Object.values(users)
    .map((user) => {
      const points = Object.values(likes)
        .filter((like) => {
          return (userProductRelation[like.postId] || {}).userId === user.id;
        })
        .filter((like) => {
          return like.userId !== user.id;
        }).length;
      return {
        ...user,
        points
      };
    })
    .sort((a, b) => b.points - a.points)
    .slice(0, 5);

  const topBrands = Object.keys(brands)
    .map((brandId) => {
      const points = Object.values(userProductRelation).filter(
        (userProduct) => {
          return userProduct.brandId === brandId && userProduct.updated;
        }
      ).length;
      return {
        ...brands[brandId],
        points,
        id: brandId
      };
    })
    .sort((a, b) => b.points - a.points)
    .slice(0, 5);

  return (
    <div className={styles.videos} style={{ zIndex: showTabsOnly ? 1 : 0 }}>
      <div
        className={styles.tabs}
        style={{
          top: showTabsOnly ? 0 : 60
        }}
      >
        <button
          onClick={() => history.replace('?tab=0')}
          style={{ color: tab === 0 ? 'black' : 'inherit' }}
        >
          <img
            src={fire}
            width="16"
            style={{ marginBottom: 4, marginRight: 6 }}
          />
          最新
        </button>
        <button
          onClick={() => history.replace('?tab=1')}
          style={{ color: tab === 1 ? 'black' : 'inherit' }}
        >
          影片
        </button>
        <button
          onClick={() => history.replace('?tab=2')}
          style={{ color: tab === 2 ? 'black' : 'inherit' }}
        >
          圖片
        </button>
        <button
          onClick={() => history.replace('?tab=3')}
          style={{ color: tab === 3 ? 'black' : 'inherit' }}
        >
          貼文
        </button>
      </div>
      <div
        className={styles.video}
        ref={videoRef}
        style={{
          height: showVideo ? 'auto' : 155
        }}
      >
        {showVideo && (
          <Slider {...postSliderSettings}>
            {promteBanners.map((banner) => {
              return <img key={banner.id} src={banner.banner} />;
            })}
          </Slider>
        )}
      </div>
      <div className={styles.rank}>
        <div className={styles.top}>
          <p>TOP 用戶</p>
          <div className={styles.list}>
            {topUsers.map((user) => (
              <div className={styles.item} key={user.id}>
                <img
                  src={user.imageUrl || '/member.png'}
                  onClick={() => history.push(`/user/${user.id}`)}
                />
                <div className={styles.itemName}>{user.displayName}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.top}>
          <p>TOP 店家</p>
          <div className={styles.list}>
            {topBrands.map((brand) => (
              <div className={styles.item} key={brand.id}>
                <img
                  src={brand.imageUrl}
                  onClick={() => history.push(`/brands/${brand.id}`)}
                />
                <div className={styles.itemName}>{brand.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.posts}>
        <div className={styles.posts1}>
          {posts
            .filter((_, index) => (columns === 2 ? index % 2 === 0 : true))
            .map((post) => {
              return (
                <Post
                  key={post.id}
                  postId={post.id}
                  post={post}
                  isDeleteDisabled
                />
              );
            })}
        </div>
        {columns === 2 && (
          <div className={styles.posts2}>
            {posts
              .filter((_, index) => index % 2 === 1)
              .map((post) => {
                return (
                  <Post
                    key={post.id}
                    postId={post.id}
                    post={post}
                    isDeleteDisabled
                  />
                );
              })}
          </div>
        )}
      </div>
      <Waypoint
        onEnter={() => {
          setNumberOfPosts(numberOfPosts + 6);
        }}
      />
      {posts.length === 0 && (
        <ReactLoading type="spinningBubbles" color="#2E8B57" />
      )}
    </div>
  );
};

export default NewVideos;
