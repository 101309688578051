import {
  SAVE_USER_PRODUCTS,
  ADD_USER_PRODUCT,
  DELETE_USER_PRODUCT,
  MODIFT_USER_PRODUCT
} from '../actions/userProducts';

export default (state = {}, action) => {
  switch (action.type) {
    case SAVE_USER_PRODUCTS:
      return {
        ...action.payload
      };
    case ADD_USER_PRODUCT:
      return {
        ...state,
        [action.payload.postId]: action.payload.post
      };
    case DELETE_USER_PRODUCT: {
      const { [action.payload]: post, ...otherPosts } = state;
      console.log(post);
      return otherPosts;
    }
    case MODIFT_USER_PRODUCT:
      return {
        ...state,
        [action.payload.postId]: action.payload.post
      };
    default:
      return state;
  }
};
