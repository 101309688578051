import { SAVE_BRANDS } from '../actions/brands';

export default (state = {}, action) => {
  switch (action.type) {
    case SAVE_BRANDS:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};