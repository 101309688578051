import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

import styles from './Policy.module.scss';
const Policy = () => (
  <div style={{ marginBottom: 72, padding: 12 }}>
    <h1>聯絡支援</h1>
    <a className={styles.center} href="mailto: theciviad@gmail.com">
      <FontAwesomeIcon icon={faEnvelope} color="#a2c198" /> Email 聯絡我們/問題或意見回饋
    </a>
    <a
      className={styles.center}
      href="https://www.facebook.com/pages/category/Product-Service/%E8%9C%A5%E8%9C%B4%E4%BB%A3%E8%A8%80-528933127632284/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faFacebookSquare} color="#a2c198" /> Facebook Messenger 聯絡
    </a>
  </div>
);

export default Policy;
