import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';

import GoogleLogo from './icons8-google.svg';
import FacebookLogo from './icons8-facebook.svg';
import styles from './LoginModal.module.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleProvider } from '../../index';

const LoginModal = ({ closeLoginModal }) => {
  const [action, setAction] = React.useState('login');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const history = useHistory();

  const register = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        window.alert('註冊成功！');
      })
      .catch(() => {
        window.alert('註冊失敗，請稍後再試');
      });
  };

  const login = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push('/brands');
        window.alert('登入成功！');
        closeLoginModal();
      })
      .catch(() => {
        window.alert('帳號或密碼錯誤，請重新輸入');
      });
  };
  const sendMail = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        window.alert('已寄送重設密碼信');
      })
      .catch(() => {
        window.alert('寄送重設密碼信失敗，請稍後再試');
      });
  };

  const buttonText = () => {
    switch (action) {
      case 'login':
        return '登入';
      case 'register':
        return '註冊';
      case 'forgot':
        return '寄送密碼重設信';
      default:
        return '登入';
    }
  };

  const renderHint = () => {
    const hints = [
      {
        action: 'login',
        element: (
          <a
            onClick={() => {
              setAction('login');
              setEmail('');
              setPassword('');
            }}
          >
            已經成為會員？
          </a>
        )
      },
      {
        action: 'register',
        element: (
          <a
            onClick={() => {
              setAction('register');
              setEmail('');
              setPassword('');
            }}
          >
            尚未成為會員？
          </a>
        )
      },
      {
        action: 'forgot',
        element: (
          <a
            onClick={() => {
              setAction('forgot');
              setEmail('');
              setPassword('');
            }}
          >
            忘記密碼？
          </a>
        )
      }
    ];
    const filteredHints = hints
      .filter(hint => hint.action !== action)
      .map(hint => hint.element);
    return [filteredHints[0], ' / ', filteredHints[1]];
  };

  const handleClick = () => {
    switch (action) {
      case 'login':
        login();
        break;
      case 'register':
        register();
        break;
      case 'forgot':
        sendMail();
        break;
      default:
    }
  };

  return (
    <div className={styles.modalWrapper} onKeyPress={e => {
      if (e.key === 'Enter') {
        if (action === 'login') login();
      }
    }}>
      <div className={styles.form}>
        <input
          type="email"
          placeholder="請輸入信箱"
          className={styles.email}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        {action !== 'forgot' && (
          <input
            type="password"
            placeholder="請輸入密碼"
            className={styles.password}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        )}
        <button onClick={handleClick} className={styles.login}>
          {buttonText()}
        </button>
        <div className={styles.hint}>{renderHint()}</div>
        <button
          onClick={() => {
            firebase
              .auth()
              .signInWithPopup(GoogleProvider)
              .then(() => {
                history.push('/brands');
                window.alert('登入成功！');
              });
          }}
          className={styles.googleLogin}
        >
          <img src={GoogleLogo} />
          Google 登入
        </button>
        <button
          onClick={() => {
            window.FB.login();
          }}
          className={styles.facebookLogin}
        >
          <img src={FacebookLogo} />
          Facebook 登入
        </button>
      </div>
      <button onClick={closeLoginModal} className={styles.buttonClose}>
        <FontAwesomeIcon icon={faTimes} size="lg" color="black" />
      </button>
    </div>
  );
};

export default React.memo(LoginModal);
