import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Menu } from 'element-react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faSearch,
  faTimes,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import includes from 'lodash/includes';
import { animateScroll } from 'react-scroll';

import LogoGreen from './new-logo-green.png';
import LogoWhite from './new-logo-white.png';
import styles from './Header.module.scss';
import LoginModalContainer from '../LoginModal/LoginModalContainer';
import Guide from './Guide';
import AddToCartModal from '../Product/AddToCartModal';
import Fire from '../../images/fire.png';
import Store from './store.svg';
import Camera from './camera.svg';
import Person from './person.svg';
import Inbox from './inbox.svg';
import { useDispatch, useSelector } from 'react-redux';
import { showGuide } from '../../redux/actions/guide';
import { getMessages } from '../../utils/firebase';
import guideJpg from './guide.jpg';

const Header = ({ userInfo, saveUserInfo }) => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.guide.show);
  const userProducts = useSelector((state) => state.userProducts);
  const products = useSelector((state) => state.products);
  const users = useSelector((state) => state.users);
  const brands = useSelector((state) => state.brands);
  const history = useHistory();
  const location = useLocation();
  const color = location.pathname === '/' ? 'white' : '#a2c198';
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [showCartModal, setShowCartModal] = useState(false);
  const [isGuideShow, setIsGuideShow] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    setShowCartModal(false);
  }, [color]);

  useEffect(() => {
    function checkLoginState(event) {
      if (event.authResponse) {
        const credential = firebase.auth.FacebookAuthProvider.credential(
          event.authResponse.accessToken
        );
        firebase
          .auth()
          .signInWithCredential(credential)
          .then(() => {
            history.push('/brands');
            window.alert('登入成功！');
          })
          .catch((error) => {
            if (
              error.code === 'auth/account-exists-with-different-credential'
            ) {
              window.alert('重複的註冊信箱');
            }
          });
      } else {
        firebase.auth().signOut();
      }
    }
    window.FB.Event.subscribe('auth.authResponseChange', checkLoginState);
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userRef = firebase.database().ref(`users/${user.uid}`);
        userRef.on('value', (snapshot) => {
          const userInfo = snapshot.val() || {};
          if (!userInfo.id) {
            userRef.update({
              displayName: user.displayName,
              imageUrl: user.photoURL,
              email: user.email,
              id: user.uid
            });
            saveUserInfo({
              displayName: user.displayName,
              imageUrl: user.photoURL,
              email: user.email,
              id: user.uid
            });
          } else {
            saveUserInfo(userInfo);
          }
          setShowLoginModal(false);
        });
      } else {
        window.FB.logout();
        saveUserInfo({});
      }
    });
  }, []);

  useEffect(() => {
    if (!window.localStorage.getItem('visited')) {
      dispatch(showGuide());
    }
  });

  useEffect(() => {
    if (!userInfo.id) return;
    getMessages().then((messages) => {
      const count = messages.filter((message) => {
        const shouldReceive =
          !message.receiverIds || message.receiverIds.includes(userInfo.id);
        const hasNotRead =
          !message.readerIds || !message.readerIds.includes(userInfo.id);
        return shouldReceive && hasNotRead;
      }).length;

      setUnreadCount(count);
    });
    firebase
      .firestore()
      .collection('messages')
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'modified') {
            setUnreadCount((unreadCount) => unreadCount - 1);
          }
        });
      });
  }, [userInfo.id]);

  const onClickRight = useCallback(() => {
    if (userInfo.id) setIsRightMenuOpen(true);
    else setShowLoginModal(true);
  }, [userInfo.id]);

  const myUserProductsCount = Object.values(userProducts).filter(
    (userProduct) => {
      return userProduct.userId === userInfo.id && !userProduct.updated;
    }
  ).length;

  return (
    <>
      <div className={styles.header} style={{ backgroundColor: color }}>
        <div className={styles.menu} onClick={() => setIsLeftMenuOpen(true)}>
          <FontAwesomeIcon
            icon={faBars}
            size="lg"
            color={color === '#a2c198' ? 'white' : '#a2c198'}
          />
        </div>
        <div className={styles.search} onClick={() => setIsSearching(true)}>
          <FontAwesomeIcon
            icon={faSearch}
            size="lg"
            color={color === '#a2c198' ? 'white' : '#a2c198'}
          />
        </div>
        {isSearching ? (
          <>
            <input
              className={`${styles.searchInput} ${
                color === '#a2c198' ? styles.inverted : ''
              }`}
              placeholder="找店家、產品、創作者..."
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
            <div
              className={styles.cancel}
              onClick={() => setIsSearching(false)}
            >
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                color={color === '#a2c198' ? 'white' : '#a2c198'}
              />
            </div>
          </>
        ) : (
          <>
            <Link to="/">
              <img
                src={color === '#a2c198' ? LogoWhite : LogoGreen}
                alt="Civi 蜥蜴代言"
                className={styles.logo}
              />
            </Link>
            <div className={styles.right} onClick={onClickRight}>
              {userInfo.id ? (
                <div
                  alt="使用者照片"
                  className={styles.userImage}
                  style={{
                    backgroundImage: `url(${
                      userInfo.imageUrl || '/member.png'
                    })`
                  }}
                >
                  {unreadCount > 0 && (
                    <div className={styles.count}>{unreadCount}</div>
                  )}
                </div>
              ) : (
                <div className={styles.go}>免費註冊/登入</div>
                // <img src={color === '#a2c198' ? LoginWhite : LoginGreen} alt="登入" className={styles.login} onClick={() => setShowLoginModal(true)} />
              )}
            </div>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.tabs}>
          <div
            className={cx(
              styles.tab,
              location.pathname === '/' && styles.active
            )}
          >
            <img
              height="30"
              src={Fire}
              onClick={() => {
                if (location.pathname === '/') animateScroll.scrollToTop();
                else history.push('/');
              }}
            />
          </div>
          <div
            className={cx(
              styles.tab,
              location.pathname.includes('/brands') && styles.active
            )}
            onClick={() => {
              if (location.pathname === '/brands') animateScroll.scrollToTop();
              history.push('/brands');
            }}
          >
            <img height="30" src={Store} />
          </div>
          <div
            className={styles.tab}
            onClick={() => {
              if (userInfo.id) {
                if (!window.localStorage.getItem('visited-free-type')) {
                  setIsGuideShow(true);
                  window.localStorage.setItem('visited-free-type', true);
                }
                setShowCartModal(true);
              } else {
                setShowLoginModal(true);
              }
            }}
          >
            <img height="30" src={Camera} />
          </div>
          <div
            className={cx(
              styles.tab,
              location.pathname === '/me' && styles.active
            )}
            onClick={() => {
              if (userInfo.id) {
                if (location.pathname === '/me') animateScroll.scrollToTop();
                history.push('/me');
              } else {
                setShowLoginModal(true);
              }
            }}
          >
            <img height="30" src={Inbox} />
            {myUserProductsCount > 0 && (
              <div className={styles.hint}>{myUserProductsCount}</div>
            )}
          </div>
          <div
            className={cx(
              styles.tab,
              location.pathname.includes('/user/') && styles.active
            )}
            onClick={() => {
              if (userInfo.id) {
                if (location.pathname.includes('/user/'))
                  animateScroll.scrollToTop();
                history.push(`/user/${userInfo.id}`);
              } else {
                setShowLoginModal(true);
              }
            }}
          >
            <img height="30" src={Person} />
          </div>
        </div>
        <div className={styles.company}>
          <div>
            <a className={styles.center} href="mailto: theciviad@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} color="white" />
            </a>
            <a
              className={styles.center}
              href="https://www.facebook.com/pages/category/Product-Service/%E8%9C%A5%E8%9C%B4%E4%BB%A3%E8%A8%80-528933127632284/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookSquare} color="white" />
            </a>
          </div>
          © 2021 希藝數位服務有限公司
        </div>
      </div>
      {isSearching && (
        <div className={styles.searchResult}>
          {Object.entries(brands)
            .filter(([, brand]) => {
              if (inputValue.trim() === '') return false;
              return (brand.title || '').includes(inputValue.trim());
            })
            .map(([brandId, brand]) => {
              return (
                <div
                  key={brandId}
                  className={styles.row}
                  onClick={() => {
                    setIsSearching(false);
                    setIsSearching('');
                    history.push(`/brands/${brandId}`);
                  }}
                >
                  {brand.title}
                </div>
              );
            })}
          {Object.entries(products)
            .filter(([, product]) => {
              if (inputValue.trim() === '') return false;
              return product.name.includes(inputValue.trim());
            })
            .map(([productId, product]) => {
              return (
                <div
                  key={productId}
                  className={styles.row}
                  onClick={() => {
                    setIsSearching(false);
                    setIsSearching('');
                    history.push(`/products/${productId}`);
                  }}
                >
                  {product.name}
                </div>
              );
            })}
          {Object.entries(users)
            .filter(([, user]) => {
              if (inputValue.trim() === '') return false;
              return (user.displayName || '').includes(inputValue.trim());
            })
            .map(([userId, user]) => {
              return (
                <div
                  key={userId}
                  className={styles.row}
                  onClick={() => {
                    setIsSearching(false);
                    setIsSearching('');
                    history.push(`/user/${userId}`);
                  }}
                >
                  {user.displayName || ''}
                </div>
              );
            })}
          {Object.entries(userProducts)
            .filter(([, userProduct]) => {
              if (inputValue.trim() === '') return false;
              return (
                includes(userProduct.title, inputValue.trim()) ||
                includes(userProduct.value, inputValue.trim())
              );
            })
            .map(([id, userProduct]) => {
              return (
                <div
                  key={id}
                  className={styles.row}
                  onClick={() => {
                    setIsSearching(false);
                    setIsSearching('');
                    history.push(`/user-products/${id}`);
                  }}
                >
                  {userProduct.title || ''}
                </div>
              );
            })}
        </div>
      )}
      {showCartModal && (
        <AddToCartModal
          productName="CiviPost"
          closeModal={() => setShowCartModal(false)}
        />
      )}
      {show && <Guide />}
      {showLoginModal && (
        <LoginModalContainer closeLoginModal={() => setShowLoginModal(false)} />
      )}
      {isGuideShow && (
        <div
          className={styles.wrapper}
          onClick={() => {
            setIsGuideShow(false);
          }}
        >
          <img src={guideJpg} />
        </div>
      )}
      {(isLeftMenuOpen || isRightMenuOpen) && (
        <div
          className={styles.menuWrapper}
          onClick={() => {
            setIsLeftMenuOpen(false);
            setIsRightMenuOpen(false);
          }}
        />
      )}
      <Menu
        className={cx(styles.leftMenu, { [styles.open]: isLeftMenuOpen })}
        onOpen={() => {}}
        onClose={() => {}}
        onSelect={() => {
          setIsLeftMenuOpen(false);
        }}
      >
        <Link to="/about">
          <Menu.Item index="2">關於我們</Menu.Item>
        </Link>
        <Link to="/policy">
          <Menu.Item index="3">聯絡支援</Menu.Item>
        </Link>
      </Menu>
      <Menu
        className={cx(styles.rightMenu, { [styles.open]: isRightMenuOpen })}
        onOpen={() => {}}
        onClose={() => {}}
        onSelect={(index) => {
          setIsRightMenuOpen(false);
          if (index === '1') {
            firebase.auth().signOut();
            history.push('/');
          }
        }}
      >
        <Link to={`/setting/${userInfo.id}`}>
          <Menu.Item index="4">帳號設定</Menu.Item>
        </Link>
        <Link to={`/inbox/${userInfo.id}`}>
          <Menu.Item index="3">
            收件匣{' '}
            {unreadCount > 0 && (
              <span className={styles.unreadCount}>{unreadCount}</span>
            )}
          </Menu.Item>
        </Link>
        <Link to="/campaign-form">
          <Menu.Item index="2">建立商家頁面</Menu.Item>
        </Link>
        {Object.values(brands).some(
          (brand) => brand.userId === userInfo.id
        ) && (
          <Link to="/my-brands">
            <Menu.Item index="5">我的商家頁面</Menu.Item>
          </Link>
        )}
        <Menu.Item index="1">登出</Menu.Item>
      </Menu>
    </>
  );
};

Header.propTypes = {
  color: PropTypes.string
};

Header.defaultProps = {
  color: '#a2c198'
};

export default React.memo(Header);
