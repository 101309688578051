import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import { useSelector } from 'react-redux';
import shortId from 'shortid';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import LoginModalContainer from '../LoginModal/LoginModalContainer';

import styles from './Home.module.scss';
import AddToCartModal from '../Product/AddToCartModal';

const settings = {
  infinite: true,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000
};

const Home = ({ isMine }) => {
  const brands = useSelector((state) => state.brands);
  const [showLoginModal, setShowLoginModal] = useState();
  const [showAddToCartModal, setShowAddToCartModal] = useState();
  const [userProductId, setUserProductId] = useState();
  const history = useHistory();
  const userInfo = useSelector((state) => state.user);
  const likes = useSelector((state) => state.likes);
  const posts = useSelector((state) => state.userProducts);

  const promoteBrands = Object.entries(brands)
    .filter(([, brand]) => {
      return brand.promote;
    })
    .map(([brandId, brand]) => {
      return {
        ...brand,
        id: brandId
      };
    });

  const getConsumedPoints = (brandId) => {
    const totalLikes = Object.keys(likes).filter((likeId) => {
      return likes[likeId].brandId === brandId;
    }).length;

    const brandUserProducts = Object.keys(posts)
      .map((postId) => ({
        id: postId,
        ...posts[postId]
      }))
      .filter((post) => {
        return (
          post.brandId === brandId &&
          post.type &&
          ![
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '-M9IgIsZ_kRJ0YTRBGUf'
          ].includes(post.id)
        );
      });

    const totalView = brandUserProducts.reduce(
      (view, brandUserProduct) => view + (brandUserProduct.view || 0),
      0
    );

    const totalReach = brandUserProducts.reduce(
      (reach, brandUserProduct) => reach + (brandUserProduct.reach || 0),
      0
    );

    return totalLikes + totalView + totalReach;
  };

  return (
    <>
      <div className={styles.home}>
        {!isMine && (
          <div className={styles.slider}>
            <Slider {...settings}>
              {promoteBrands.map((promoteBrand) => (
                <div key={promoteBrand.id} className={styles.bannerWrapper}>
                  <div
                    style={{ backgroundImage: `url("${promoteBrand.banner}")` }}
                    className={styles.banner}
                  />
                  <button
                    className={styles.add}
                    onClick={() => {
                      if (userInfo.id) {
                        firebase
                          .firestore()
                          .collection('posts')
                          .add({
                            created: Date.now(),
                            code: shortId.generate(),
                            userId: userInfo.id,
                            brandId: promoteBrand.id
                          })
                          .then(() => {
                            history.push('/me');
                          })
                          .catch((error) => {
                            console.error('Error adding document: ', error);
                          });
                      } else {
                        setShowLoginModal(true);
                      }
                    }}
                  >
                    ＋加入清單
                  </button>
                  <button
                    className={styles.now}
                    onClick={() => {
                      if (userInfo.id) {
                        firebase
                          .firestore()
                          .collection('posts')
                          .add({
                            created: Date.now(),
                            code: shortId.generate(),
                            userId: userInfo.id,
                            brandId: promoteBrand.id
                          })
                          .then((docRef) => {
                            setUserProductId(docRef.id);
                            setShowAddToCartModal(true);
                          })
                          .catch((error) => {
                            console.error('Error adding document: ', error);
                          });
                      } else {
                        setShowLoginModal(true);
                      }
                    }}
                  >
                    即刻創作
                  </button>
                  <button
                    className={styles.detail}
                    onClick={() => {
                      history.push(`/brands/${promoteBrand.id}`);
                    }}
                  >
                    詳細資訊...
                  </button>
                </div>
              ))}
            </Slider>
          </div>
        )}
        <div className={styles.products}>
          {Object.keys(brands)
            .filter((brandId) =>
              isMine
                ? brands[brandId].userId === userInfo.id
                : !brands[brandId].beta
            )
            .sort((a, b) => {
              const aRemainingPoints =
                brands[a].points - getConsumedPoints(a) || 0;
              const bRemainingPoints =
                brands[b].points - getConsumedPoints(b) || 0;
              return bRemainingPoints - aRemainingPoints;
            })
            .map((brandId) => (
              <div key={brandId} className={styles.product}>
                <img height="80" src={brands[brandId].imageUrl} />
                <Link to={`brands/${brandId}`}>
                  {isMine ? <div>查看頁面</div> : <div>查看代言商品</div>}
                </Link>
              </div>
            ))}
        </div>
        {!isMine && <div className={styles.more}>更多品牌陸續上架</div>}
      </div>
      {showLoginModal && (
        <LoginModalContainer closeLoginModal={() => setShowLoginModal(false)} />
      )}
      {showAddToCartModal && (
        <AddToCartModal
          // productName={product.name}
          closeModal={() => setShowAddToCartModal(false)}
          userInfo={userInfo}
          userProductId={userProductId}
        />
      )}
    </>
  );
};

export default Home;
