import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import { useDispatch } from 'react-redux';

import styles from './App.module.scss';
import Home from '../Home/Home';
import Brand from '../Brand/Brand';
import ProductContainer from '../Product/ProductContainer';
import Me from '../Me/Me';
import Privacy from '../Privacy/Privacy';
import About from '../About/About';
import Policy from '../Policy/Policy';
import CampaignForm from '../CampaignForm/CampaignForm';
import RegisteredBrand from '../RegisteredBrand/RegisteredBrand';
import NewVideos from '../NewVideos/NewVideos';
import User from '../User/User';
import Setting from '../Setting/Setting';
import HeaderContainer from '../Header/HeaderContainer';
import UserProduct from '../UserProduct/UserProduct';
import Search from '../Search/Search';
import Inbox from '../Inbox/Inbox';
import Trade from '../Trade/Trade';

import {
  saveUserProducts,
  addUserProduct,
  deleteUserProduct,
  modifyUserProduct
} from '../../redux/actions/userProducts';
import {
  saveTags,
  addTag,
  deleteTag,
  modifyTag,
} from '../../redux/actions/tags';
import { saveUsers } from '../../redux/actions/users';
import { saveBrands } from '../../redux/actions/brands';
import { saveProducts } from '../../redux/actions/products';
import { saveWithdrawals } from '../../redux/actions/withdrawals';
import { saveLikes } from '../../redux/actions/likes';
import { saveBanners } from '../../redux/actions/banners';
import { saveComments } from '../../redux/actions/comments';
import { saveFollows } from '../../redux/actions/follows';
import SendMessage from '../SendMessage/SendMessage';
import Plans from '../Plans/Plans';

function App() {
  const dispatch = useDispatch();
  const firstTimeFetchPosts = React.useRef(true);
  const firstTimeFetchTags = React.useRef(true);

  React.useEffect(() => {
    firebase
      .firestore()
      .collection('posts')
      .onSnapshot((snapshot) => {
        if (firstTimeFetchPosts.current) {
          const tmp = {};
          snapshot.forEach((doc) => {
            tmp[doc.id] = doc.data();
          });
          dispatch(saveUserProducts(tmp));
          firstTimeFetchPosts.current = false;
          return;
        }
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            dispatch(addUserProduct(change.doc.id, change.doc.data()));
          }
          if (change.type === 'modified') {
            dispatch(modifyUserProduct(change.doc.id, change.doc.data()));
          }
          if (change.type === 'removed') {
            dispatch(deleteUserProduct(change.doc.id));
          }
        });
      });
  }, []);

  React.useEffect(() => {
    firebase
      .firestore()
      .collection('tags')
      .onSnapshot((snapshot) => {
        if (firstTimeFetchTags.current) {
          const tmp = {};
          snapshot.forEach((doc) => {
            tmp[doc.id] = doc.data();
          });
          dispatch(saveTags(tmp));
          firstTimeFetchTags.current = false;
          return;
        }
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            dispatch(addTag(change.doc.id, change.doc.data()));
          }
          if (change.type === 'modified') {
            dispatch(modifyTag(change.doc.id, change.doc.data()));
          }
          if (change.type === 'removed') {
            dispatch(deleteTag(change.doc.id));
          }
        });
      });
  }, []);

  React.useEffect(() => {
    const ref = firebase.database().ref('users');
    ref.on('value', (snapshot) => {
      dispatch(saveUsers(snapshot.val()));
    });
  }, []);

  React.useEffect(() => {
    const ref = firebase.database().ref('brands');
    ref.on('value', (snapshot) => {
      dispatch(saveBrands(snapshot.val()));
    });
  }, []);

  React.useEffect(() => {
    const ref = firebase.database().ref('products');
    ref.on('value', (snapshot) => {
      dispatch(saveProducts(snapshot.val()));
    });
  }, []);

  React.useEffect(() => {
    const ref = firebase.database().ref('withdrawals');
    ref.on('value', (snapshot) => {
      dispatch(saveWithdrawals(snapshot.val()));
    });
  }, []);

  React.useEffect(() => {
    const ref = firebase.database().ref('likes');
    ref.on('value', (snapshot) => {
      dispatch(saveLikes(snapshot.val()));
    });
  }, []);

  React.useEffect(() => {
    const ref = firebase.database().ref('banners');
    ref.on('value', (snapshot) => {
      dispatch(saveBanners(snapshot.val()));
    });
  }, []);

  React.useEffect(() => {
    const ref = firebase.database().ref('comments');
    ref.on('value', (snapshot) => {
      dispatch(saveComments(snapshot.val()));
    });
  }, []);

  React.useEffect(() => {
    const ref = firebase.database().ref('follows');
    ref.on('value', (snapshot) => {
      dispatch(saveFollows(snapshot.val()));
    });
  }, []);

  /* update storage metada, cache-control, for example
  React.useEffect(() => {
    var forestRef = firebase.storage().ref().child('videos').listAll().then((res) => {
      res.items.forEach(function(itemRef) {
        itemRef.getMetadata().then(function(metadata) {
          console.log(metadata);
        }).catch(function(error) {
          // Uh-oh, an error occurred!
        });
        itemRef.updateMetadata({
          cacheControl: 'public,max-age=86400',
        }).then(function(metadata) {
          console.log(metadata);
        }).catch(function(error) {
          // Uh-oh, an error occurred!
        });
      })
    });
    
  }, []);
  */

  return (
    <>
      <Router>
        <Switch>
          <Route path="/search" component={Search} />
          <Route path="/send-message" component={SendMessage} />
          <Route
            render={() => (
              <>
                <div className={styles.body}>
                  <HeaderContainer />
                  <Route path="/" exact component={NewVideos} />
                  <Route
                    path="/brands"
                    exact
                    render={(routeProps) => <Home {...routeProps} />}
                  />
                  <Route
                    path="/my-brands"
                    exact
                    render={(routeProps) => <Home {...routeProps} isMine />}
                  />
                  <Route path="/brands/:id" exact component={Brand} />
                  <Route
                    path="/brands/:brandId/products/:id"
                    exact
                    component={ProductContainer}
                  />
                  <Route path="/me" exact component={Me} />
                  <Route path="/privacy" exact component={Privacy} />
                  <Route path="/about" exact component={About} />
                  <Route path="/policy" exact component={Policy} />
                  <Route path="/campaign-form" exact component={CampaignForm} />
                  <Route
                    path="/registered-brands"
                    exact
                    component={RegisteredBrand}
                  />
                  <Route path="/user/:id" component={User} />
                  <Route path="/setting/:id" component={Setting} />
                  <Route path="/inbox/:id" component={Inbox} />
                  <Route path="/user-products/:id" component={UserProduct} />
                  <Route path="/trade" component={Trade} />
                  <Route path="/plans" component={Plans} />
                </div>
              </>
            )}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
