import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './Product.module.scss';

const Product = ({ productId, editProduct, deleteProduct, showButtons }) => {
  const product = useSelector((state) => state.products[productId] || {});

  return (
    <div className={styles.product}>
      {product.imageUrl && <img src={product.imageUrl} />}
      {!product.imageUrl && product.imageUrls && (
        <img src={product.imageUrls[0]} />
      )}
      <div className={styles.name}>{product.name}</div>
      {showButtons && (
        <div className={styles.buttons}>
          <button
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              editProduct();
            }}
          >
            編輯
          </button>
          <button
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              deleteProduct();
            }}
          >
            刪除
          </button>
        </div>
      )}
    </div>
  );
};

Product.propTypes = {
  productId: PropTypes.string.isRequired
};

export default React.memo(Product);
