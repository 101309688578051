import React, { useEffect, useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import './CampaignForm.module.scss';

const CampaignForm = () => {
  const history = useHistory();
  const location = useLocation();
  const { brandId } = qs.parse(location.search);
  const brand = useSelector((state) => state.brands[brandId] || {});
  const userInfo = useSelector((state) => state.user);
  const [editingBrand, setEditingBrand] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    const { elements } = e.target;
    const {
      title,
      address,
      description,
      webUrl,
      facebookUrl,
      instagramUrl,
      foodpandaUrl,
      ubereatsUrl,
      buyUrl,
      locationUrl,
      phone,
      email,
      isNormal,
      isReivew,
      isClickProfit,
      isPurchaseProfit,
      imageUrl
    } = editingBrand;
    const file = elements[3].files[0];
    if (!webUrl.trim() && !facebookUrl.trim() && !instagramUrl.trim()) {
      window.alert(
        '店家/品牌官⽅網站網址、Facebook 粉絲團專頁、Instagram 專⾴ 至少填寫一項'
      );
      return;
    }
    if (brandId) {
      const uploadImage = file
        ? firebase
            .storage()
            .ref()
            .child(`brands/${brandId}/${file.name}`)
            .put(file, {
              cacheControl: 'public,max-age=86400'
            })
            .then((snapshot) => snapshot.ref.getDownloadURL())
        : Promise.resolve(imageUrl);

      uploadImage.then((downloadURL) => {
        const updateObj = {
          title,
          address,
          description,
          imageUrl: downloadURL,
          webUrl,
          facebookUrl,
          instagramUrl,
          foodpandaUrl,
          ubereatsUrl,
          buyUrl,
          locationUrl,
          phone,
          email,
          tags: {},
          beta: true,
          userId: userInfo.id
        };
        if (isNormal) updateObj.tags['一般'] = true;
        if (isReivew) updateObj.tags['需審核'] = true;
        if (isClickProfit) updateObj.tags['觀看點擊分潤'] = true;
        if (isPurchaseProfit) updateObj.tags['導購'] = true;
        firebase
          .database()
          .ref(`brands/${brandId}`)
          .update(updateObj)
          .then(() => {
            window.alert('修改成功');
            history.push('/brands');
          });
      });
    } else {
      const brandRef = firebase.database().ref('brands').push();
      firebase
        .storage()
        .ref()
        .child(`brands/${brandRef.key}/${file.name}`)
        .put(file, {
          cacheControl: 'public,max-age=86400'
        })
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            const updateObj = {
              title,
              address,
              description,
              imageUrl: downloadURL,
              webUrl,
              facebookUrl,
              instagramUrl,
              foodpandaUrl,
              ubereatsUrl,
              buyUrl,
              locationUrl,
              phone,
              email,
              tags: {},
              beta: true,
              userId: userInfo.id
            };
            if (isNormal) updateObj.tags['一般'] = true;
            if (isReivew) updateObj.tags['需審核'] = true;
            if (isClickProfit) updateObj.tags['觀看點擊分潤'] = true;
            if (isPurchaseProfit) updateObj.tags['導購'] = true;
            brandRef.set(updateObj).then(() => {
              window.alert('建立成功');
              history.push('/brands');
            });
          });
        });
    }
  };
  useEffect(() => {
    if (brand.title && (!brand.userId || brand.userId !== userInfo.id)) {
      history.push('/');
    }
  }, [brand, userInfo]);

  useEffect(() => {
    if (brand.title) {
      setEditingBrand(brand);
    }
  }, [brand]);

  const updateEditingBrand = (update) => {
    setEditingBrand({
      ...editingBrand,
      ...update
    });
  };

  return (
    <Container>
      <h3>{brandId ? '修改' : '建立'}商家頁面</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="company">
          <Form.Label>商家名稱</Form.Label>
          <Form.Control
            type="text"
            required
            value={editingBrand.title}
            onChange={(e) => updateEditingBrand({ title: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Label>商家地址</Form.Label>
          <Form.Control
            type="text"
            required
            value={editingBrand.address}
            onChange={(e) => updateEditingBrand({ address: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="intro">
          <Form.Label>介紹文案</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            required
            value={editingBrand.description}
            onChange={(e) =>
              updateEditingBrand({ description: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group controlId="logo">
          <Form.Label>
            商標圖檔
            <div
              style={{
                width: 100,
                height: 100,
                border: '1px solid #ced4da',
                marginTop: '0.5em',
                backgroundImage: `url(${editingBrand.imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                cursor: 'pointer'
              }}
            />
          </Form.Label>
          <Form.File
            hidden
            onChange={(e) => {
              const file = e.target.files[0];
              updateEditingBrand({ imageUrl: URL.createObjectURL(file) });
            }}
          />
        </Form.Group>
        <hr />
        <Form.Group controlId="url">
          <Form.Label>商家/品牌官⽅網站網址</Form.Label>
          <Form.Control
            type="text"
            value={editingBrand.webUrl}
            onChange={(e) => updateEditingBrand({ webUrl: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="url">
          <Form.Label>Facebook 粉絲團專頁</Form.Label>
          <Form.Control
            type="text"
            value={editingBrand.facebookUrl}
            onChange={(e) =>
              updateEditingBrand({ facebookUrl: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group controlId="url">
          <Form.Label>Instagram 專⾴</Form.Label>
          <Form.Control
            type="text"
            value={editingBrand.instagramUrl}
            onChange={(e) =>
              updateEditingBrand({ instagramUrl: e.target.value })
            }
          />
          <Form.Text muted>
            商家/品牌官⽅網站網址、Facebook 粉絲團專頁、Instagram 專⾴
            至少填寫一項
          </Form.Text>
        </Form.Group>
        <hr />
        <Form.Group controlId="foodpandaUrl">
          <Form.Label>foodpanda 網址</Form.Label>
          <Form.Control
            type="text"
            value={editingBrand.foodpandaUrl}
            onChange={(e) =>
              updateEditingBrand({ foodpandaUrl: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group controlId="ubereatsUrl">
          <Form.Label>Uber Eats 網址</Form.Label>
          <Form.Control
            type="text"
            value={editingBrand.ubereatsUrl}
            onChange={(e) =>
              updateEditingBrand({ ubereatsUrl: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group controlId="buyUrl">
          <Form.Label>商城網址</Form.Label>
          <Form.Control
            type="text"
            value={editingBrand.buyUrl}
            onChange={(e) => updateEditingBrand({ buyUrl: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="locationUrl">
          <Form.Label>Google Map 網址</Form.Label>
          <Form.Control
            type="text"
            value={editingBrand.locationUrl}
            onChange={(e) =>
              updateEditingBrand({ locationUrl: e.target.value })
            }
          />
          <Form.Text muted>
            創作者可透過以上方式取得物品，您可供創作者「試用、租借或需自費購入產品」等方式提供產品協助創作
          </Form.Text>
        </Form.Group>
        <hr />
        <Form.Group controlId="contact">
          <Form.Label>聯絡電話</Form.Label>
          <Form.Control
            type="text"
            required
            value={editingBrand.phone}
            onChange={(e) => updateEditingBrand({ phone: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="contact">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            type="text"
            required
            value={editingBrand.email}
            onChange={(e) => updateEditingBrand({ email: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="contact">
          <Form.Check
            inline
            label="一般"
            type="checkbox"
            checked={editingBrand.isNormal}
            onChange={(e) => updateEditingBrand({ isNormal: e.target.checked })}
          />
          <Form.Check
            inline
            label="需審核"
            type="checkbox"
            checked={editingBrand.isReivew}
            onChange={(e) => updateEditingBrand({ isReview: e.target.checked })}
          />
          <Form.Check
            inline
            label="觀看點擊分潤"
            type="checkbox"
            checked={editingBrand.isClickProfit}
            onChange={(e) =>
              updateEditingBrand({ isClickProfit: e.target.checked })
            }
          />
          <Form.Check
            inline
            label="導購分潤"
            type="checkbox"
            checked={editingBrand.isPurchaseProfit}
            onChange={(e) =>
              updateEditingBrand({ isPurchaseProfit: e.target.checked })
            }
          />
          <Form.Text muted>標籤供創作者快速辨識創作規範</Form.Text>
          <Form.Text muted>
            一般：無任何審核條件規範，在不違反 Civimender
            蜥蜴代言網站基本原則即可隨興創作發佈作品。
            <br />
            需審核：須遵守該品牌專頁所列審核項目，審核後即可公開作品。
            <br />
            觀看點擊分潤：使用點擊觀看計數供創作者獲得廣告分潤。
            <br />
            導購分潤：使用專用的導購連結，透過連結的交易單數獲得廣告分潤。
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          {brandId ? '修改' : '建立'}
        </Button>
      </Form>
    </Container>
  );
};

export default React.memo(CampaignForm);
