import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';

import styles from './Likes.module.scss';
import UserListModal from '../UserListModal/UserListModal';

const Likes = (props) => {
  const { userProductId } = props;
  const [showModal, setShowModal] = React.useState(false);
  const userInfo = useSelector((state) => state.user);
  const userProduct = useSelector(
    (state) => state.userProducts[userProductId] || {}
  );
  const likes = useSelector((state) => state.likes);

  const likeId = Object.keys(likes).find((likeId) => {
    const like = likes[likeId];
    return like.postId === userProductId && like.userId === userInfo.id;
  });

  const userProductLikes = Object.values(likes).filter(
    (like) => like.postId === userProductId
  );

  return (
    <>
      <div className={styles.like}>
        <FontAwesomeIcon
          icon={likeId ? faHeart : faHeartRegular}
          color="#FF5151"
          size="1x"
          onClick={() => {
            if (!userInfo) return;

            if (likeId) {
              firebase.database().ref(`likes/${likeId}`).remove();
            } else {
              const likeRef = firebase.database().ref('likes').push();
              likeRef.set({
                postId: userProductId,
                userId: userInfo.id,
                brandId: userProduct.brandId || ''
              });
            }
          }}
        />
        <div
          className={styles.likeCount}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {userProductLikes.length}
        </div>
      </div>
      <UserListModal
        show={showModal}
        userIds={userProductLikes.map((like) => like.userId)}
        handleClose={() => setShowModal(false)}
      />
    </>
  );
};

export default React.memo(Likes);
