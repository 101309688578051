import { combineReducers } from 'redux';

import user from './user';
import guide from './guide';
import animation from './animation';
import userProducts from './userProducts';
import users from './users';
import brands from './brands';
import products from './products';
import withdrawals from './withdrawals';
import likes from './likes';
import banners from './banners';
import comments from './comments';
import follows from './follows';
import tags from './tags';

export default combineReducers({
  user,
  guide,
  animation,
  userProducts,
  users,
  brands,
  products,
  withdrawals,
  likes,
  banners,
  comments,
  follows,
  tags
});
