import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';

import { Button, Container, Form } from 'react-bootstrap';

const SendMessage = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const { elements } = e.target;
    const title = elements[0].value;
    const content = elements[1].value;
    const file = elements[2].files[0];
    const receiverId = elements[3].value;
    const message = {
      title,
      content,
      created: firebase.firestore.Timestamp.now()
    };
    if (receiverId) {
      message.receiverIds = [receiverId];
    }
    firebase
      .firestore()
      .collection('messages')
      .add(message)
      .then((docRef) => {
        return file
          ? firebase
              .storage()
              .ref()
              .child(`messages/${docRef.id}/${file.name}`)
              .put(file, {
                cacheControl: 'public,max-age=86400'
              })
              .then((snapshot) => {
                return snapshot.ref.getDownloadURL().then((downloadURL) => {
                  const updateObj = {
                    imageUrl: downloadURL
                  };
                  return firebase
                    .firestore()
                    .collection('messages')
                    .doc(docRef.id)
                    .update(updateObj);
                });
              })
          : Promise.resolve();
      })
      .then(() => {
        window.alert('發送成功');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Container>
      <h1>發送訊息</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>標題</Form.Label>
          <Form.Control type="text" required />
        </Form.Group>
        <Form.Group controlId="content">
          <Form.Label>內文</Form.Label>
          <Form.Control as="textarea" rows="3" required />
        </Form.Group>
        <Form.Group controlId="image">
          <Form.Label>圖片</Form.Label>
          <Form.File />
        </Form.Group>
        <Form.Group controlId="receiverId">
          <Form.Label>收件使用者 ID</Form.Label>
          <Form.Control type="text" />
        </Form.Group>
        <Button variant="primary" type="submit">
          發送
        </Button>
      </Form>
    </Container>
  );
};

SendMessage.propTypes = {};

SendMessage.defaultProps = {};

export default React.memo(SendMessage);
