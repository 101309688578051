export const SHOW_GUIDE = 'SHOW_GUIDE';
export const HIDE_GUIDE = 'HIDE_GUIDE';

export const showGuide = () => ({
  type: SHOW_GUIDE,
});

export const hideGuide = () => ({
  type: HIDE_GUIDE,
});
