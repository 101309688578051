export const SAVE_TAGS = 'SAVE_TAGS';
export const ADD_TAG = 'ADD_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const MODIFT_TAG = 'MODIFY_TAG';

export const saveTags = (tags) => ({
  type: SAVE_TAGS,
  payload: tags
});

export const addTag = (tagId, tag) => ({
  type: ADD_TAG,
  payload: {
    tagId,
    tag
  }
});

export const deleteTag = (tagId) => ({
  type: DELETE_TAG,
  payload: tagId
});

export const modifyTag = (tagId, tag) => ({
  type: MODIFT_TAG,
  payload: {
    tagId,
    tag
  }
});
