import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CiviModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      {!props.hideHeader && (
        <Modal.Header closeButton>
          {props.title && <Modal.Title>{props.title}</Modal.Title>}
        </Modal.Header>
      )}
      <Modal.Body>{props.body}</Modal.Body>
      {!props.hideFooter && (
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            取消
          </Button>
          <Button variant="primary" onClick={props.handleClick}>
            確認
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CiviModal;
