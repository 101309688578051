import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import ReactLoading from 'react-loading';
import shortId from 'shortid';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './Product.module.scss';
import LoginModalContainer from '../LoginModal/LoginModalContainer';
import AddToCartModal from './AddToCartModal';
import GetItem from '../GetItem/GetItem';

const settings = {
  infinite: false,
  arrows: false,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Product = () => {
  const { id: productId, brandId } = useParams();
  const userInfo = useSelector((state) => state.user);
  const product = useSelector((state) => state.products[productId] || {});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showAddToCartModal, setShowAddToCartModal] = useState(false);
  const [userProductId, setUserProductId] = useState('');
  const [showGetItem, setShowGetItem] = useState(false);

  return (
    <>
      <div className={styles.product}>
        {product ? (
          <>
            {/*<div className={styles.hint}>{
              product.videos ? (
                `這個項目已經有 ${Object.keys(product.videos).length} 個代言創作`
              ) : (
                '這個項目尚未有代言創作'
              )
            }</div>*/}
            {product.imageUrls ? (
              <div className={styles.sliderWrapper}>
                <Slider {...settings}>
                  {product.imageUrls.map((imageUrl) => {
                    return <img key={imageUrl} src={imageUrl} />;
                  })}
                </Slider>
              </div>
            ) : (
              <img width="240" src={product.imageUrl} />
            )}
            <div className={styles.title}>{product.name}</div>
            <div className={styles.description}>{product.description}</div>
            <div
              style={{ marginBottom: 8, fontWeight: 'bold' }}
              onClick={() => {
                if (userInfo.id) {
                  setShowGetItem(true);
                } else {
                  setShowLoginModal(true);
                }
              }}
            >
              取得物品
            </div>
            <button
              onClick={() => {
                if (userInfo.id) {
                  firebase
                    .firestore()
                    .collection('posts')
                    .add({
                      created: Date.now(),
                      code: shortId.generate(),
                      userId: userInfo.id,
                      productId,
                      brandId
                    })
                    .then((docRef) => {
                      setUserProductId(docRef.id);
                      setShowAddToCartModal(true);
                    })
                    .catch((error) => {
                      console.error('Error adding document: ', error);
                    });
                } else {
                  setShowLoginModal(true);
                }
              }}
            >
              選取開始代言創作！
            </button>
          </>
        ) : (
          <ReactLoading type="spinningBubbles" color="#2E8B57" />
        )}
      </div>
      {showLoginModal && (
        <LoginModalContainer closeLoginModal={() => setShowLoginModal(false)} />
      )}
      {showAddToCartModal && (
        <AddToCartModal
          productName={product.name}
          closeModal={() => setShowAddToCartModal(false)}
          userInfo={userInfo}
          userProductId={userProductId}
        />
      )}
      {showGetItem && (
        <GetItem
          productId={productId}
          brandId={brandId}
          closeModal={() => {
            setShowGetItem(false);
          }}
        />
      )}
    </>
  );
};

export default React.memo(Product);
