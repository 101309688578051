import React from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import Compressor from 'compressorjs';

import styles from './AddToCartModal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faCamera, faEdit } from '@fortawesome/free-solid-svg-icons';

import NewPost from '../NewPost/NewPost';
import SelectTopic from './SelectTopic';
import YoutubeUrlInputModal from './YoutubeUrlInputModal';
import ProgressBarModal from '../ProgressBarModal/ProgressBarModal';

const AddToCartModal = ({
  closeModal,
  productName,
  hideVideo,
  userProductId
}) => {
  const videoRef = React.useRef();
  const imageRef = React.useRef();
  const [isCreating, setIsCreating] = React.useState(false);
  const [creatingType, setCreatingType] = React.useState();
  const [imageUrls, setImageUrls] = React.useState([]);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [isSelectingTopic, setIsSelectingTopic] = React.useState(false);
  const [isInputUrl, setIsInputUrl] = React.useState(false);
  const [isUploadingFile, setIsUploadingFile] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  return (
    <>
      <div className={styles.modalWrapper} onClick={closeModal}>
        <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
          <div className={styles.title}>
            {productName}
            <p>選擇創作類型</p>
          </div>
          <div className={styles.icons}>
            {!hideVideo && (
              <div
                className={styles.icon}
                onClick={() => {
                  setIsSelectingTopic(true);
                }}
              >
                <FontAwesomeIcon icon={faVideo} size="2x" />
                <p>短片</p>
                <input
                  type="file"
                  accept="video/*"
                  {...(/iPad|iPhone|iPod/.test(navigator.platform || '')
                    ? {}
                    : {
                        capture: 'camera'
                      })}
                  style={{ display: 'none' }}
                  ref={videoRef}
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file.size > 1024 * 1024 * 1024) {
                      window.alert('檔案過大，請重新上傳小於 1 GB 的影片');
                      return;
                    }
                    setIsUploadingFile(true);
                    const uploadTask = firebase
                      .storage()
                      .ref()
                      .child(`posts/${userProductId}/${file.name}`)
                      .put(file, {
                        cacheControl: 'public,max-age=86400'
                      });

                    const promise = new Promise((resolve) => {
                      uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                          setProgress(
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                              100
                          );
                        },
                        () => {},
                        () => {
                          uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then((downloadURL) => {
                              resolve(downloadURL);
                            });
                        }
                      );
                    });
                    const fileUrl = await promise;
                    setVideoUrl(fileUrl);
                    setIsCreating(true);
                    setCreatingType('video');
                    setIsUploadingFile(false);
                    if (videoRef.current) {
                      videoRef.current.value = '';
                    }
                  }}
                />
              </div>
            )}
            <div
              className={styles.icon}
              onClick={() => imageRef.current.click()}
            >
              <FontAwesomeIcon icon={faCamera} size="2x" />
              <p>圖片</p>
              <input
                multiple
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={imageRef}
                onChange={async (e) => {
                  setIsUploadingFile(true);
                  const uploadFiles = Array.prototype.map.call(
                    e.target.files,
                    (file) => {
                      return new Promise((resolve, reject) => {
                        new Compressor(file, {
                          quality: 0.6,
                          success: (result) => {
                            const uploadTask = firebase
                              .storage()
                              .ref()
                              .child(`posts/${userProductId}/${result.name}`)
                              .put(result, {
                                cacheControl: 'public,max-age=86400'
                              });
                            uploadTask.on(
                              'state_changed',
                              (snapshot) => {
                                setProgress(
                                  (snapshot.bytesTransferred /
                                    snapshot.totalBytes) *
                                    100
                                );
                              },
                              () => {},
                              () => {
                                uploadTask.snapshot.ref
                                  .getDownloadURL()
                                  .then((downloadURL) => {
                                    resolve(downloadURL);
                                  });
                              }
                            );
                          },
                          error(err) {
                            reject(err);
                          }
                        });
                      });
                    }
                  );
                  const urls = await Promise.all(uploadFiles);
                  setImageUrls(urls);
                  setIsCreating(true);
                  setCreatingType('image');
                  setIsUploadingFile(false);
                  if (imageRef.current) {
                    imageRef.current.value = '';
                  }
                }}
              />
            </div>
            <div
              className={styles.icon}
              onClick={() => {
                setIsCreating(true);
                setCreatingType('post');
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="2x" />
              <p>貼文</p>
            </div>
          </div>
          {!hideVideo && productName !== 'CiviPost' && (
            <p>
              上傳的「短片」務必在作品內容資訊中標註代言清單中該項的「civi代碼」並設定「未公開」，以便加速審核時間。
              <br />
              短片完成後請至 <Link to="/me">代言清單</Link>{' '}
              中回傳作品連結才完成商品代言。
            </p>
          )}
        </div>
      </div>
      {isSelectingTopic && (
        <SelectTopic
          closeModal={() => setIsSelectingTopic(false)}
          onSelectTopic={() => videoRef.current.click()}
          userProductId={userProductId}
          openYoutubeUrlInputModal={() => setIsInputUrl(true)}
        />
      )}
      {isInputUrl && (
        <YoutubeUrlInputModal
          closeModal={() => setIsInputUrl(false)}
          onInputUrl={(videoUrl) => {
            setIsCreating(true);
            setCreatingType('video');
            setVideoUrl(videoUrl);
          }}
          userProductId={userProductId}
        />
      )}
      {isCreating && (
        <NewPost
          imageUrls={imageUrls}
          creatingType={creatingType}
          cancel={() => {
            setIsCreating(false);
            setImageUrls([]);
            setVideoUrl('');
          }}
          close={() => {
            setIsCreating(false);
            setIsSelectingTopic(false);
            closeModal();
            setImageUrls([]);
            setVideoUrl('');
          }}
          userProductId={userProductId}
          productName={productName}
          videoUrl={videoUrl}
        />
      )}
      {isUploadingFile && <ProgressBarModal progress={progress} />}
    </>
  );
};

export default React.memo(AddToCartModal);
