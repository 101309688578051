import React, { useState, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCamera } from '@fortawesome/free-solid-svg-icons';

import styles from './Product.module.scss';
import AddToCartModal from '../Product/AddToCartModal';
import GetItem from '../GetItem/GetItem';
import NewPost from '../NewPost/NewPost';
import { topics } from '../Product/SelectTopic';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Product = ({
  productId,
  created,
  updated,
  code,
  userProductId,
  brandId,
  coins,
  setSelectedUserProductId
}) => {
  const [showNewPost, setShowNewPost] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [showGetItem, setShowGetItem] = useState(false);
  const brand = useSelector((state) => state.brands[brandId] || {});
  const product = useSelector((state) => state.products[productId] || {});
  const userProduct = useSelector(
    (state) => state.userProducts[userProductId] || {}
  );
  const history = useHistory();
  const videoRef = useRef();
  const verifyRef = useRef();

  return (
    <div className={styles.productWrapper}>
      <div className={styles.product} id={userProductId}>
        {product || brand ? (
          <>
            <div
              style={{
                backgroundImage: `url(${product.imageUrl || brand.imageUrl})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: 90,
                width: 120
              }}
              onClick={() => {
                if (updated) {
                  history.push(`/user-products/${userProductId}`);
                }
              }}
            />
            <div>{product.name || brand.title}</div>
            <div>{new Date(created).toLocaleDateString('zh-TW')} 加入</div>
            {updated ? (
              <>
                <div>{new Date(updated).toLocaleDateString('zh-TW')} 開始</div>
                <div style={{ color: 'red' }}>
                  {new Date(
                    updated + 14 * 24 * 60 * 60 * 1000
                  ).toLocaleDateString('zh-TW')}{' '}
                  到期
                </div>
                分潤：${coins || 0}
                {userProduct.isWithdrawn && (
                  <>
                    <br />
                    已提領
                  </>
                )}
                {(userProduct.type === 'post' ||
                  userProduct.type === 'image') &&
                  userProduct.isVerified === undefined && (
                    <button
                      disabled={Date.now() > updated + 15 * 24 * 60 * 60 * 1000}
                      className={styles.verify}
                      onClick={() => {
                        verifyRef.current.click();
                      }}
                    >
                      回傳驗證影片
                    </button>
                  )}
                {(userProduct.type === 'post' ||
                  userProduct.type === 'image') &&
                  userProduct.isVerified !== undefined && (
                    <div className={styles.verificationStatus}>
                      {!userProduct.isVerified ? '驗證中' : '驗證通過'}
                    </div>
                  )}
                <input
                  type="file"
                  accept="video/*"
                  style={{ display: 'none' }}
                  ref={verifyRef}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    firebase
                      .storage()
                      .ref()
                      .child(`verify/${userProductId}/${file.name}`)
                      .put(file, {
                        cacheControl: 'public,max-age=86400'
                      })
                      .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((downloadURL) => {
                          firebase
                            .firestore()
                            .collection('posts')
                            .doc(userProductId)
                            .update({
                              isVerified: false,
                              verificationVideoUrl: downloadURL
                            });
                          if (verifyRef.current) {
                            verifyRef.current.value = '';
                          }
                        });
                      });
                  }}
                />
              </>
            ) : (
              <>
                <div>Civi 代碼：{code}</div>
                <button onClick={() => setShowGetItem(true)}>
                  尚未取得物品？
                </button>
                <button onClick={() => setShowNewPost(true)}>
                  回傳影片連結
                </button>
                <button onClick={() => setShowCartModal(true)}>
                  發佈圖片/貼文
                </button>
              </>
            )}
          </>
        ) : (
          <ReactLoading type="spinningBubbles" color="black" />
        )}
        {!updated && (
          <button
            className={styles.delete}
            onClick={() => {
              const result = window.confirm('確定要刪除此項代言嗎？');
              if (!result) return;
              firebase
                .firestore()
                .collection('posts')
                .doc(userProductId)
                .delete();
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} size="1x" />
          </button>
        )}
      </div>
      {!updated && (
        <div className={styles.topicWrapper}>
          {userProduct.topic ? (
            <div
              className={styles.topic}
              onClick={() => {
                setSelectedUserProductId(userProductId);
              }}
            >
              {
                (topics.find((topic) => topic.key === userProduct.topic) || {})
                  .title
              }
            </div>
          ) : (
            <div
              className={styles.noTopic}
              onClick={() => {
                setSelectedUserProductId(userProductId);
              }}
            >
              主題自訂
            </div>
          )}
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => videoRef.current.click()}
          >
            <FontAwesomeIcon icon={faCamera} size="2x" color="orange" />
            <input
              type="file"
              accept="video/*"
              {...(/iPad|iPhone|iPod/.test(navigator.platform || '')
                ? {}
                : {
                    capture: 'camera'
                  })}
              style={{ display: 'none' }}
              ref={videoRef}
            />
          </div>
        </div>
      )}
      {showNewPost && (
        <NewPost
          creatingType="video"
          cancel={() => {
            setShowNewPost(false);
          }}
          close={() => {
            setShowNewPost(false);
          }}
          userProductId={userProductId}
        />
      )}
      {showCartModal && (
        <AddToCartModal
          productName={product.name}
          closeModal={() => setShowCartModal(false)}
          hideVideo
          userProductId={userProductId}
        />
      )}
      {showGetItem && (
        <GetItem
          productId={productId}
          brandId={brandId}
          closeModal={() => {
            setShowGetItem(false);
          }}
        />
      )}
    </div>
  );
};

export default React.memo(Product);
