import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch } from 'react-redux';

import Step1 from './1.svg';
import Step2 from './2.svg';
import Step3 from './3.svg';
import Step4 from './4.svg';
import styles from './Guide.module.scss';
import { hideGuide } from '../../redux/actions/guide';

const Guide = () => {
  const dispatch = useDispatch();
  const sliderRef = React.createRef();
  const settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className={styles.wrapper}>
      <Slider {...settings} ref={sliderRef}>
        <img src={Step1} onClick={() => sliderRef.current.slickNext()}/>
        <img src={Step2} onClick={() => sliderRef.current.slickNext()}/>
        <img src={Step3} onClick={() => sliderRef.current.slickNext()}/>
        <img src={Step4} onClick={() => {
          window.localStorage.setItem('visited', true);
          dispatch(hideGuide());
        }} />
      </Slider>
    </div>
  );
};

export default React.memo(Guide);