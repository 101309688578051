import { SAVE_TAGS, ADD_TAG, DELETE_TAG, MODIFT_TAG } from '../actions/tags';

export default (state = {}, action) => {
  switch (action.type) {
    case SAVE_TAGS:
      return {
        ...action.payload
      };
    case ADD_TAG:
      return {
        ...state,
        [action.payload.tagId]: action.payload.tag
      };
    case DELETE_TAG: {
      const { [action.payload]: tag, ...otherTags } = state;
      console.log(tag);
      return otherTags;
    }
    case MODIFT_TAG:
      return {
        ...state,
        [action.payload.tagId]: action.payload.tag
      };
    default:
      return state;
  }
};
