import React from 'react';
import { customAlphabet } from 'nanoid';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import firebase from 'firebase/app';
import 'firebase/firestore';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);

const Trade = (props) => {
  const { brandId } = qs.parse(useLocation().search);
  const docId = nanoid();
  const ChoosePayment = 'Credit';
  const ClientBackURL = `https://www.civimender.com/brands/${brandId}`;
  const EncryptType = '1';
  const ExecTimes = 99;
  const Frequency = 1;
  const ItemName = props.name;
  // dev
  // const MerchantID = '2000132';
  // production
  const MerchantID = '3231645';
  const MerchantTradeNo = `${props.id}${docId}`;
  const PaymentType = 'aio';
  const PeriodAmount = props.price;
  const PeriodReturnURL =
    'https://us-central1-civi-a4924.cloudfunctions.net/api/notify';
  const PeriodType = 'M';
  const ReturnURL =
    'https://us-central1-civi-a4924.cloudfunctions.net/api/notify';
  const TotalAmount = props.price;
  const TradeDesc = props.name;

  const handleClick = () => {
    const MerchantTradeDate = new Date()
      .toISOString()
      .replace(/-/g, '/')
      .replace(/T/, ' ')
      .replace(/\..+/, '');
    document.getElementById('MerchantTradeDate').value = MerchantTradeDate;

    const string = props.period
      ? `ChoosePayment=${ChoosePayment}&ClientBackURL=${ClientBackURL}&EncryptType=${EncryptType}&ExecTimes=${ExecTimes}&Frequency=${Frequency}&ItemName=${ItemName}&MerchantID=${MerchantID}&MerchantTradeDate=${MerchantTradeDate}&MerchantTradeNo=${MerchantTradeNo}&PaymentType=${PaymentType}&PeriodAmount=${PeriodAmount}&PeriodReturnURL=${PeriodReturnURL}&PeriodType=${PeriodType}&ReturnURL=${ReturnURL}&TotalAmount=${TotalAmount}&TradeDesc=${TradeDesc}`
      : `ChoosePayment=${ChoosePayment}&ClientBackURL=${ClientBackURL}&EncryptType=${EncryptType}&ItemName=${ItemName}&MerchantID=${MerchantID}&MerchantTradeDate=${MerchantTradeDate}&MerchantTradeNo=${MerchantTradeNo}&PaymentType=${PaymentType}&ReturnURL=${ReturnURL}&TotalAmount=${TotalAmount}&TradeDesc=${TradeDesc}`;

    fetch('https://us-central1-civi-a4924.cloudfunctions.net/api/encrypt', {
      method: 'POST',
      body: string
    })
      .then((res) => res.text())
      .then((CheckMacValue) => {
        document.getElementById('CheckMacValue').value = CheckMacValue;
        firebase
          .firestore()
          .collection('orders')
          .doc(MerchantTradeNo)
          .set({
            brandId,
            payment: [],
            updated: Date.now(),
            created: Date.now()
          })
          .then(() => {
            document.getElementById('form').submit();
          });
      });
  };
  return (
    <>
      <form
        method="post"
        // dev
        // action="https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V5"
        // production
        action="https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5"
        id="form"
      >
        <input type="hidden" name="ChoosePayment" value={ChoosePayment} />
        <input type="hidden" name="ClientBackURL" value={ClientBackURL} />
        <input type="hidden" name="EncryptType" value={EncryptType} />
        {props.period && (
          <input type="hidden" name="ExecTimes" value={ExecTimes} />
        )}
        {props.period && (
          <input type="hidden" name="Frequency" value={Frequency} />
        )}
        <input type="hidden" name="ItemName" value={ItemName} />
        <input type="hidden" name="MerchantID" value={MerchantID} />
        <input type="hidden" name="MerchantTradeDate" id="MerchantTradeDate" />
        <input type="hidden" name="MerchantTradeNo" value={MerchantTradeNo} />
        <input type="hidden" name="PaymentType" value={PaymentType} />
        {props.period && (
          <input type="hidden" name="PeriodAmount" value={PeriodAmount} />
        )}
        {props.period && (
          <input type="hidden" name="PeriodReturnURL" value={PeriodReturnURL} />
        )}
        {props.period && (
          <input type="hidden" name="PeriodType" value={PeriodType} />
        )}
        <input type="hidden" name="ReturnURL" value={ReturnURL} />
        <input type="hidden" name="TotalAmount" value={TotalAmount} />
        <input type="hidden" name="TradeDesc" value={TradeDesc} />
        <input type="hidden" name="CheckMacValue" id="CheckMacValue" />
      </form>
      <button onClick={handleClick}>結帳</button>
    </>
  );
};

Trade.propTypes = {};

Trade.defaultProps = {};

export default React.memo(Trade);
