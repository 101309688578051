import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import PropTypes from 'prop-types';

import './ProductForm.module.scss';

const ProductForm = ({ brandId, productId, closeModal, editingProduct }) => {
  const isEditing = !!editingProduct.name;
  const [product, setProduct] = useState(editingProduct);
  const handleSubmit = (e) => {
    e.preventDefault();
    const { elements } = e.target;
    const { name, description, imageUrls } = product;
    const files = elements[2].files;
    const uploadImages = () => {
      if (files.length === 0) return Promise.resolve(imageUrls);

      const uploadImagePromises = [...files].map((file) => {
        return firebase
          .storage()
          .ref()
          .child(`brands/${brandId}/${file.name}`)
          .put(file, {
            cacheControl: 'public,max-age=86400'
          })
          .then((snapshot) => snapshot.ref.getDownloadURL());
      });

      return Promise.all(uploadImagePromises);
    };
    if (isEditing) {
      uploadImages().then((downloadURLs) => {
        const updateObj = {
          name,
          description,
          imageUrls: downloadURLs
        };
        firebase
          .database()
          .ref(`products/${productId}`)
          .update(updateObj)
          .then(() => {
            window.alert('修改成功');
            closeModal();
          });
      });
    } else {
      const productRef = firebase.database().ref('products').push();
      uploadImages()
        .then((imageUrls) => {
          return productRef.set({
            name,
            description,
            imageUrls
          });
        })
        .then(() => {
          return firebase
            .database()
            .ref(`brands/${brandId}/products/${productRef.key}`)
            .set(true);
        })
        .then(() => {
          window.alert('上架成功');
          closeModal();
        });
    }
  };

  const updateProduct = (updateObj) => {
    setProduct({
      ...product,
      ...updateObj
    });
  };

  return (
    <Container>
      <h3>{isEditing ? '修改' : '上架'}商品</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name">
          <Form.Label>商品名稱</Form.Label>
          <Form.Control
            type="text"
            required
            value={product.name}
            onChange={(e) => updateProduct({ name: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>介紹文案</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            required
            value={product.description}
            onChange={(e) => updateProduct({ description: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="files">
          <Form.Label>
            <div>商品圖片</div>
            {product.imageUrl && (
              <div
                style={{
                  width: 100,
                  height: 100,
                  border: '1px solid #ced4da',
                  marginTop: '0.5em',
                  backgroundImage: `url(${product.imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  cursor: 'pointer'
                }}
              />
            )}
            {!product.imageUrl &&
              product.imageUrls &&
              product.imageUrls.map((imageUrl) => (
                <div
                  key={imageUrl}
                  style={{
                    width: 100,
                    height: 100,
                    border: '1px solid #ced4da',
                    marginTop: '0.5em',
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    cursor: 'pointer',
                    display: 'inline-block'
                  }}
                />
              ))}
            {!product.imageUrl && !product.imageUrls && (
              <div
                style={{
                  width: 100,
                  height: 100,
                  border: '1px solid #ced4da',
                  marginTop: '0.5em',
                  cursor: 'pointer',
                  display: 'inline-block'
                }}
              />
            )}
          </Form.Label>
          <Form.File
            hidden
            multiple
            onChange={(e) => {
              const files = e.target.files;
              updateProduct({
                imageUrls: [...files].map((file) => URL.createObjectURL(file))
              });
            }}
          />
        </Form.Group>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="secondary" type="button" onClick={closeModal}>
            取消
          </Button>
          <Button variant="primary" type="submit">
            {isEditing ? '修改' : '上架'}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

ProductForm.propTypes = {
  brandId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default React.memo(ProductForm);
