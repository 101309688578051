import { PLAYED_ANIMATION } from '../actions/animation';

export default (state = {
  played: false
}, action) => {
  switch (action.type) {
    case PLAYED_ANIMATION:
      return {
        played: true
      };
    default:
      return state;
  }
};