import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import linkifyStr from 'linkifyjs/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleDown,
  faEye,
  faCommentAlt,
  faClone,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './Post.module.scss';
import iconArrowRight from '../../images/iconArrowRight.svg';
import iconShare from '../../images/share.svg';
import iconContext from '../../images/context.svg';
import logo from './logo.png';
import { getTimeAgo } from '../UserProduct/UserProduct';
import NewPost from '../NewPost/NewPost';
import Likes from '../Likes/Likes';
import { getYoutubeEmbedUrl } from '../../utils/utils';

const Post = ({ post, postId, isDeleteDisabled, clickDeleteToDislike }) => {
  const history = useHistory();
  const user = useSelector((state) => state.users[post.userId] || {});
  const [brand, setBrand] = React.useState({});
  const [product, setProduct] = React.useState({});
  const [isCreating, setIsCreating] = React.useState(false);
  const userInfo = useSelector((state) => state.user);
  const likes = useSelector((state) => state.likes);
  const filteredComments = useSelector((state) =>
    Object.keys(state.comments).filter(
      (commentId) => state.comments[commentId].userProductId === postId
    )
  );
  const postTag = useSelector((state) => state.tags[post.tagId]);
  React.useEffect(() => {
    const brandRef = firebase.database().ref(`brands/${post.brandId}`);
    brandRef.once('value', (snapshot) => {
      setBrand(snapshot.val() || {});
    });
    const productRef = firebase.database().ref(`products/${post.productId}`);
    productRef.once('value', (snapshot) => {
      setProduct(snapshot.val() || {});
    });
  }, []);

  const { videoUrl } = post;

  return (
    <>
      <div className={styles.post} id={postId}>
        <div className={styles.postBody}>
          <a
            className={styles.postBrand}
            onClick={() => {
              if (post.brandId) history.push(`/brands/${post.brandId}`);
            }}
          >
            {post.brandId && (
              <>
                <img height="24" src={brand.imageUrl} /> {brand.title}{' '}
                {product.name}
              </>
            )}
            {!post.brandId && (
              <>
                <img height="24" src={logo} />{' '}
                <span style={{ color: '#a2c198' }}>CiviPost</span>
              </>
            )}
          </a>
          {post.imageUrl && (
            <img
              alt="貼文照片"
              className={styles.postImage}
              src={post.imageUrl}
            />
          )}
          {post.imageUrls && post.imageUrls[0] && (
            <img
              alt="貼文照片"
              className={styles.postImage}
              src={post.imageUrls[0]}
            />
          )}
          {post.imageUrls && post.imageUrls.length > 1 && (
            <FontAwesomeIcon
              icon={faClone}
              color="#a2c198"
              size="1x"
              className={styles.multiple}
            />
          )}
          {(post.fileUrl || getYoutubeEmbedUrl(videoUrl)) &&
            (post.fileUrl ? (
              <video controls>
                <source src={post.fileUrl}></source>
              </video>
            ) : (
              <iframe
                src={getYoutubeEmbedUrl(videoUrl)}
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ))}
          <Link className={styles.postTitle} to={`/user-products/${postId}`}>
            {post.title}
          </Link>
          {post.value && (
            <p
              className={styles.postContent}
              dangerouslySetInnerHTML={{
                __html: linkifyStr(post.value, {
                  attributes: {
                    rel: 'noopener noreferrer',
                    style: 'text-decoration: underline; color: #2E8B57;'
                  }
                })
              }}
            />
          )}
          {postTag?.name && (
            <div
              className={styles.postTag}
              style={{
                backgroundColor: postTag.color || 'green',
                borderLeftColor: postTag.color || 'green'
              }}
            >
              {postTag.userIds.length > 0 && (
                <span
                  className="pr-1 mr-1"
                  style={{
                    borderRight: '1px dashed white',
                    fontFamily: 'inherit',
                    fontWeight: 'inherit'
                  }}
                >
                  <FontAwesomeIcon icon={faUser} className="mr-1" />
                  {postTag.userIds.length}
                </span>
              )}
              <span style={{ fontSize: 16, verticalAlign: -2 }}>
                {postTag.emoji}
              </span>{' '}
              {postTag.name}
            </div>
          )}
          {postTag?.name && post.public && (
            <div className={styles.postPublic}>標籤公告</div>
          )}
          {post.created && (
            <p className={styles.postCreated}>{getTimeAgo(post.created)}</p>
          )}
        </div>
        <div className={styles.info}>
          <div
            className={styles.user}
            onClick={() => {
              if (post.userId) {
                history.push(`/user/${post.userId}`);
              }
            }}
          >
            <div
              className={styles.userPhoto}
              style={{
                backgroundImage: `url(${user.imageUrl || '/member.png'})`
              }}
            />
            <div className={styles.userName}>{user.displayName}</div>
          </div>
          <div
            style={{ display: 'flex', width: 60, justifyContent: 'flex-end' }}
          >
            {typeof post.view === 'number' && (
              <div className={styles.view}>
                <FontAwesomeIcon
                  icon={faEye}
                  color="rgba(128, 128, 128, 0.5)"
                />
                <div className={styles.viewCount}>{post.view}</div>
              </div>
            )}
            <OverlayTrigger
              trigger="click"
              overlay={
                <Tooltip>
                  <div className={styles.tooltip}>
                    {(post.originalUrl || post.videoUrl || post.postUrl) && (
                      <div
                        onClick={() => {
                          window.open(
                            post.originalUrl || post.videoUrl || post.postUrl
                          );
                        }}
                        className={styles.openLink}
                      >
                        <img
                          src={iconArrowRight}
                          className={styles.arrow}
                          width="23"
                        ></img>
                        開啟連結
                      </div>
                    )}
                    <div
                      onClick={() => {
                        window.FB.ui({
                          method: 'share',
                          href: `https://www.civimender.com/user-products/${postId}`
                        });
                      }}
                      className={styles.share}
                    >
                      <img
                        src={iconShare}
                        className={styles.arrow}
                        width="16"
                      ></img>
                      分享
                    </div>
                  </div>
                </Tooltip>
              }
              rootClose
              rootCloseEvent="click"
            >
              <div className={styles.contextButton}>
                <img src={iconContext} width="20" />
              </div>
            </OverlayTrigger>
            <div
              className={styles.comment}
              onClick={() => {
                history.push(`/user-products/${postId}`);
              }}
            >
              <FontAwesomeIcon icon={faCommentAlt} color="#a2c198" size="1x" />
              <div className={styles.commentCount}>
                {filteredComments.length}
              </div>
            </div>
            <Likes userProductId={postId} />
          </div>
        </div>
        {!isDeleteDisabled && !isCreating && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Tooltip>
                {!clickDeleteToDislike && (
                  <span
                    onClick={() => {
                      setIsCreating(true);
                    }}
                  >
                    編輯
                  </span>
                )}
                <span
                  onClick={() => {
                    if (clickDeleteToDislike) {
                      const result = window.confirm(
                        '確定要取消收藏此篇貼文嗎？'
                      );
                      if (!result) return;
                      const likeId = Object.keys(likes).find((likeId) => {
                        const like = likes[likeId];
                        return (
                          like.postId === postId && like.userId === userInfo.id
                        );
                      });
                      firebase.database().ref(`likes/${likeId}`).remove();
                    } else {
                      const result = window.confirm('確定要刪除此篇貼文嗎？');
                      if (!result) return;
                      firebase
                        .firestore()
                        .collection('posts')
                        .doc(postId)
                        .delete();
                    }
                  }}
                >
                  刪除
                </span>
              </Tooltip>
            }
            rootClose
            rootCloseEvent="click"
          >
            <button className={styles.delete}>
              <FontAwesomeIcon
                icon={faChevronCircleDown}
                color="gray"
                width={4}
              />
            </button>
          </OverlayTrigger>
        )}
      </div>
      {isCreating && (
        <NewPost
          imageUrls={post.imageUrls}
          creatingType={post.type}
          cancel={() => {
            setIsCreating(false);
          }}
          close={() => {
            setIsCreating(false);
          }}
          userProductId={postId}
          productName={product.name}
          videoUrl={post.videoUrl}
          title={post.title}
          value={post.value}
          hideUploadImage
        />
      )}
    </>
  );
};

Post.propTypes = {};

Post.defaultProps = {};

export default React.memo(Post);
