import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';

import styles from './SelectTopic.module.scss';
import vlog from './vlog.png';
import unbox from './unbox.png';
import clip from './clip.png';
import rank from './rank.png';
import funny from './funny.png';
import comment from './comment.png';
import knowledge from './knowledge.png';
import tutorial from './tutorial.png';
import mother from './mother.png';
import experience from './experience.png';

const settings = {
  infinite: false,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

export const topics = [
  {
    key: 'vlog',
    title: 'Vlog 影片',
    image: vlog,
    text:
      '文字部落格的影片版，以影片日記的形式記錄下日常生活或即時感想，任何裝置都可以錄製，是創作門檻較低的主題'
  },
  {
    key: 'unbox',
    title: '開箱',
    image: unbox,
    text: '打開包裝並描述它的內容物，影片製作門檻低，是很熱門的主題之一'
  },
  {
    key: 'clip',
    title: '超短片',
    image: clip,
    text:
      '短視頻、短影音，總片長不超過30~60秒或以下，特殊轉場效果拍攝精緻短片內容，是熱門主題之一'
  },
  {
    key: 'rank',
    title: '比較與排行',
    image: rank,
    text:
      '比較商家品牌最推薦的產品，排行各商品間的差異，能提出明顯差異或推薦的物品較能吸引觀看'
  },
  {
    key: 'funny',
    title: '笑點搞笑',
    image: funny,
    text:
      '使用較具笑點的型式製作影片，如惡搞、搞笑等等，這種主題無法立即製作， 需花費較長的時間錄製'
  },
  {
    key: 'comment',
    title: '測試與評論',
    image: comment,
    text:
      '評測某一商品或服務，利用影片傳達體驗和評論，可以只用一台手機就拍出，是相對比較好產出作品的主題'
  },
  {
    key: 'knowledge',
    title: '知識型',
    image: knowledge,
    text:
      '對某領域有很深程度的了解，用影片傳達個人見解，製作這種主題需事先準備齊全資料'
  },
  {
    key: 'tutorial',
    title: '教學型',
    image: tutorial,
    text: '以短片作品演示一個教學過程，在製作的過程中分享自己的技能或見解'
  },
  {
    key: 'mother',
    title: '媽媽',
    image: mother,
    text: '不知道要拍什麼主題，也許可以問媽媽'
  },
  {
    key: 'experience',
    title: '體驗實習打工',
    image: experience,
    text:
      '利用你的個人魅力，當個一日店長或員工選擇此主題需要充足的準備與事先聯絡店家'
  }
];

const SelectTopic = (props) => {
  const {
    closeModal,
    onSelectTopic,
    userProductId,
    openYoutubeUrlInputModal
  } = props;

  return (
    <div
      className={styles.modalWrapper}
      onClick={(e) => {
        if (e.target === e.currentTarget) closeModal();
      }}
    >
      <div className={styles.modal}>
        <div className={styles.title}>選擇主題</div>
        <div className={styles.sliderWrapper}>
          <Slider {...settings}>
            {topics.map((topic, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.topicTitle}>{topic.title}</div>
                <img src={topic.image} />
                <div className={styles.topicText}>{topic.text}</div>
                <button
                  className={styles.topicStart}
                  onClick={() => {
                    if (userProductId) {
                      firebase
                        .firestore()
                        .collection('posts')
                        .doc(userProductId)
                        .update({
                          topic: topic.key
                        });
                    }
                    onSelectTopic();
                  }}
                >
                  開始
                </button>
              </div>
            ))}
          </Slider>
        </div>
        <div className={styles.buttonsWrapper}>
          <button
            className={styles.topicFree}
            onClick={() => {
              if (userProductId) {
                firebase
                  .firestore()
                  .collection('posts')
                  .doc(userProductId)
                  .update({
                    topic: ''
                  });
              }
              onSelectTopic();
            }}
          >
            隨意自訂
          </button>
          {openYoutubeUrlInputModal && !userProductId && (
            <button
              className={styles.topicFree}
              onClick={openYoutubeUrlInputModal}
            >
              輸入內嵌網址
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

SelectTopic.propTypes = {};

SelectTopic.defaultProps = {};

export default React.memo(SelectTopic);
