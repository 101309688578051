export const SAVE_USER_PRODUCTS = 'SAVE_USER_PRODUCTS';
export const ADD_USER_PRODUCT = 'ADD_USER_PRODUCT';
export const DELETE_USER_PRODUCT = 'DELETE_USER_PRODUCT';
export const MODIFT_USER_PRODUCT = 'MODIFY_USER_PRODUCT';

export const saveUserProducts = (userProducts) => ({
  type: SAVE_USER_PRODUCTS,
  payload: userProducts
});

export const addUserProduct = (postId, post) => ({
  type: ADD_USER_PRODUCT,
  payload: {
    postId,
    post
  }
});

export const deleteUserProduct = (postId) => ({
  type: DELETE_USER_PRODUCT,
  payload: postId
});

export const modifyUserProduct = (postId, post) => ({
  type: MODIFT_USER_PRODUCT,
  payload: {
    postId,
    post
  }
});
