import React from 'react';
import Trade from '../Trade/Trade';

import styles from './Plans.module.scss';

const PLANS = [
  {
    id: 'points',
    title: '儲點方案',
    buttonText: '選我',
    details: [
      '付款即開通品牌專頁',
      '刊登天數至點數用盡',
      '自主決定儲值點數',
      '價格自由彈性',
      '獲得業配創作至點數用盡',
      '站內、Instagram、Facebook、YouTube 同步曝光',
      '免費查看創作者資料庫'
    ]
  },
  {
    id: 'monthly1',
    title: (
      <>
        月繳方案
        <br />
        每月 699 元
      </>
    ),
    buttonText: '選我',
    details: [
      '付款即開通品牌專頁',
      '每月固定刊登',
      '每月現金點數 3500 點',
      '點數餘額每月累計',
      '每月可能獲得 1~3 件以上業配作品',
      '站內、Instagram、Facebook、YouTube 同步曝光',
      '免費查看創作者資料庫'
    ]
  },
  {
    id: 'monthly2',
    title: (
      <>
        月繳方案
        <br />
        每月 1,799 元
      </>
    ),
    buttonText: '選我',
    details: [
      '付款即開通品牌專頁',
      '每月固定刊登',
      '每月現金點數 6800 點',
      '點數餘額每月累計',
      '在品牌頁面「置頂」廣告一則',
      '每月可能獲得 1~5 件以上業配作品',
      '站內、Instagram、Facebook、YouTube 同步曝光',
      '免費查看創作者資料庫'
    ]
  },
  {
    id: 'special',
    title: '專案服務',
    buttonText: '聯絡專人',
    details: [
      '依需求客制報價',
      '專門窗口聯繫',
      '詳盡的需求調查',
      '推薦符合的需求選擇',
      '提供期間成效表單'
    ]
  }
];

const Plans = () => {
  const [plan, setPlan] = React.useState();
  const [points, setPoints] = React.useState(1000);
  if (plan === 'points') {
    return (
      <div className={styles.wrapper}>
        <h2>選擇您的儲點方案</h2>
        <div className={styles.points}>
          <h3>選擇購買您的現金點數數量</h3>
          <input
            type="number"
            min="1000"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />
          <h3>每個現金點數=$0.26，四捨五入計算</h3>
          <div className={styles.line}>|</div>
          <div className={styles.fee}>
            方案費用
            <br />${Math.round(points * 0.26)}
          </div>
          <Trade id="P001" name="儲點方案" price={Math.round(points * 0.26)} />
        </div>
      </div>
    );
  }
  if (plan === 'monthly1' || plan === 'monthly2') {
    return (
      <div className={styles.wrapper}>
        <h2>確認您的方案</h2>
        <div className={styles.points}>
          <div className={styles.fee}>
            方案費用
            <br />${plan === 'monthly1' && 699}
            {plan === 'monthly2' && 1799}
          </div>
          <Trade
            id={plan === 'monthly1' ? 'P002' : 'P003'}
            name={plan === 'monthly1' ? '月繳方案699' : '月繳方案1799'}
            price={plan === 'monthly1' ? 699 : 1799}
            period
          />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <h2>選擇您的方案</h2>
      <div className={styles.plans}>
        {PLANS.map((plan, i) => (
          <div className={styles.plan} key={i.toString()}>
            <div className={styles.topLeftRing} />
            <div className={styles.topRightRing} />
            <div className={styles.bottomLeftRing} />
            <div className={styles.bottomRightRing} />
            <div className={styles.whiteMask}>
              <div className={styles.title}>{plan.title}</div>
              <button onClick={() => setPlan(plan.id)}>
                {plan.buttonText}
              </button>
              <ul>
                {plan.details.map((detail, j) => (
                  <li key={j.toString()}>{detail}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginBottom: 72, padding: 12 }}>
        <h2>FAQ</h2>
        <br />
        <h2>1.什麼是「CIVI代幣」?</h2>
        <br />
        <p>
          不管是儲值方案或月繳方案，您所購買到的CIVI代幣將會轉換為創作者的廣告分潤，本站用戶或創作者的作品發佈後就能獲得您所提供的CIVI代幣，這將是創作者創作作品的最大動力!
        </p>
        <br />
        <br />
        <br />
        <h2>2.哪些企業品牌適合刊登方案?</h2>
        <br />
        <p>
          若您有SEO熱搜、作品素材、想創造品牌討論話題的需求，或是正在經營Facebook粉絲團與Instagram商業帳戶想獲得實際的社群行銷助力，都適合使用本站刊登曝光，您的付出是可以獲得看得見的具體作品發佈。
        </p>
        <br />
        <br />
        <br />
        <h2>3.我能聯繫創作者嗎?</h2>
        <br />
        <p>
          可以，您可自由查看創作者的資料頁面，可看見過去的作品與聯繫方式，如您需要的是專門符合您企業品牌的創作或是特殊服務可選擇「專案服務」，我們將能提供比獨立創作者報價更為優惠的價格為您建立專門窗口聯繫並提供您的客製方案。
        </p>
        <br />
      </div>
    </div>
  );
};

Plans.propTypes = {};

Plans.defaultProps = {};

export default React.memo(Plans);
