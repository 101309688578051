import React from 'react';
import Intro01 from './intro-01.svg';
import Compycost01 from './compycost01.jpg';
import Authrincom from './authrincom.jpg';
import Reve0105 from './reve01-05.jpg';
import Reve0106 from './reve01-06.jpg';

const About = () => (
  <>
    <div style={{ marginBottom: 72, padding: 12 }}>
      <h1>使用Civimender 蜥蜴代言 社群平台</h1>
      <br />
      <br />
      <a href={Intro01} target="_blank" rel="noopener noreferrer"><img src={Intro01} style={{ width: '100%' }} /></a>
      <br />
      <br />
      <br />
      <h2>代言企業/品牌：</h2>
      <br />
      <p>
        創作者可在平台上自由選擇有興趣的產品，拍攝影片、照片、繪圖等等不限方式公開Youtube、Facebook
        粉絲團、Instagram成為品牌大使，本站將接收該作品創作者的作品連結回傳，透過此連結可計算作品點擊率，或使用者連結至企業該產品目的網站，創作者能在成為品牌大使開始的「14天」內透過點擊賺取利潤，成功獲得大量關注與點擊的創作者則有機會成為官方代言人。
      </p>
      <p>
        成為品牌大使、代言人可包括各式素人、模特兒、演員、歌手、個人、團體、創作人物，不限真實人物等等，使用創作人物禁止借用已有商標著作權人物，必須在網站上註冊成為品牌大使才能選擇商家並製作、回傳作品，在網站上尋找所喜歡、有興趣、有特殊性的品牌、產品或是商家，選擇完成後就開始拍攝、上傳影片至Youtube等指定平台，務必將通過審核的作品影片網址回傳至本站，所有流程完成後便會計算觀看者點擊，自審核通過公開作品的「14天」都能獲得點擊收入，創作者所拍攝完成影片每觀看點擊得
        0.15~0.3元現金收益，另一獲得收益功能：使用合作企業訂單連結嵌入作品並抽取一定比率作為佣金，創作企業廣告預算上以抽成計費者作品，每個表單連結可獲得
        8%獎金，在企業資訊下架前無任何時限，以上能成功獲得大量關注與點擊的創作者與作品則有機會成為官方代言人。
      </p>
      <p>一般流程如下：</p>
      <ol>
        <li>選擇代言創作項目</li>
        <li>提供試用產品 (依店家提供與否)</li>
        <li>產出作品，回傳作品審核</li>
      <li>作品上傳至 Youtube 等指定平台</li>
        <li>曝光開始計算點擊獎金</li>
      </ol>
      <p>如商家有規範細節，作品完成後必須回傳影片通過審核</p>
      <p>審核方式如下：</p>
      <ol>
        <li>選擇企業品牌</li>
        <li>
          製作影片作品，作品必須設定為「非公開」、符合Civimender 蜥蜴代言 社群平台（以下簡稱本站）與企業規範
        </li>
        <li>作品回傳至本站審核</li>
        <li>
          本站提交作品給予合作商家官方審核
          <p>
            審核「通過」：公開作品直接上架
          </p>
          <p>
            審核「不通過」：本站告知創作者違規項目，無發佈作品，無任何點擊分潤產生
          </p>
        </li>
        <li>審核成功並上傳影片，作品設定為「公開」，開始計算點擊分潤</li>
        <li>完成</li>
      </ol>
      <br />
      <p>企業品牌上架會提示標籤供創作者快速辨識創作規範</p>
      <p>標籤分類如下：</p>
      <ol>
        <li>
          一般：無任何審核條件規範，在不違反Civimender 蜥蜴代言社群網站基本原則即可隨興創作發佈作品。
        </li>
        <li>需審核：須遵守該品牌專頁所列審核項目，審核後即可公開作品。</li>
        <li>觀看點擊分潤：使用點擊觀看計數供創作者獲得廣告分潤。</li>
        <li>導購分潤：使用專用的導購連結，透過連結的交易單數獲得廣告分潤。</li>
      </ol>
      <br />
      <br />
      <h2>商家申請建立代言專案：</h2>
      <br />
      <p>品牌店家可在此填寫您所要求的代言徵求活動條件與說明，通過審查後的代言徵求專案將上架在「品牌代言專案區」中，供創作者依照品牌提出的說明內容去進行創作影片、貼文或圖片作品。此區的代言徵件專案有其時效性，在徵件的截止時間前，發佈徵件專案的廠商可依自己喜好，選擇適合的一個作品作為此徵件專案的優勝者，優勝創作者將獲得通知且獲得廠商專案提出的「代言人獎金」，若廠商沒有在徵件專案活動有效期間選擇一位優勝者並發出獎金，系統將在專案結束前5日通知專案發佈者由「最多點擊觀看」作品選為優勝者，在截止日後14日內系統將通知該創作者為優勝並自動發出該專案獎金給優勝者。</p>
      <br />
      <br />
      <br />
      <br />
      <h2>CiviPost發佈功能：</h2>
      <br />
      <p>使用CiviPost發佈功能創作者可隨意發佈作品，發佈的作品並不代言任何品牌，所以不限制商家的創作規範，此版同樣可發佈影片、圖片、貼文作品，純粹分享創作內容沒有廣告分潤計算；品牌店家能夠經由此版自行挑選所喜愛的作品內容做配對，當選定作品配匹配完成時就能使用影片做推廣，而廣告預算點擊額度也會計入抵扣，創作者在品牌匹配完成時也一同開始計算廣告點擊分潤。</p>
      <br />
      <p>用戶也能使用CiviPost進行「隱私標籤」型態發佈，您可以自訂「標籤」後讓您允許的用戶查看您標籤內的發佈內容。標籤內容的發佈將首發一則「標籤公告」讓用戶看見您所新創立的標籤，而之後該標籤的發佈則只有您允許的用戶可查看，如未設定標籤的CiviPost預設將是「公開」型態的發佈。欲將已設立的「標籤」刪除，則被刪除的標籤的發佈內容將會轉為「公開發佈」。</p>
      <br />
      <h2>企業/品牌上架：</h2>
      <br />
      <p>
        店家或產品在本站上架後給使用者供選擇成為品牌大使，產品與品牌的介紹是吸引網站上的創作者拍攝影片條件，商家務必提供官方網站網址、活動網址或目標網址，如沒有則提供
        Facebook粉絲團或
        Instagram頁面；我們將提出預算方案，企業匯出預算後將會把企業品牌、產品上架至本站，創作者即可挑選平台的店家製作影片，廣告預算方案如合作企業要求在不滿資訊上架後的第一支作品上線公開
        14日內(包含14日)撤出，本站保有權力不做任何退費。
      </p>
      <p>所需提供的資料：</p>
      <ol>
        <li>店家/品牌介紹文案</li>
        <li>產品介紹文案</li>
        <li>店家/品牌Logo圖片檔</li>
        <li>產品獨照圖片檔</li>
        <li>產品敘述圖或是情境圖</li>
        <li>
          店家/品牌官方網站網址、Facebook粉絲團專頁、Instagram專頁(至少提供一項)
        </li>
        <li>公司窗口、E-mail、聯絡方式</li>
      </ol>
      <a href={Compycost01} target="_blank" rel="noopener noreferrer"><img src={Compycost01} style={{ width: '100%' }} /></a>
      Civimender 蜥蜴代言 社群網站所有發佈的內容是由第三方提供者上傳到網站，如影片、圖片、文字描述、連結等內容。在進入網站與閱讀內容時，本站不能保證第三方提供者的內容，網站閱聽者需詳讀此免責聲明。
      <br />
      <br />
      <br />
      <h2>帳戶說明：</h2>
      <br />
      在Civimender 蜥蜴代言 社群網站創建一個帳戶，由此帳戶瀏覽、上傳內容與使用網站的各種功能，完整帳戶需年滿
      18 歲或有 18 歲以上的法定監護人可透過
      本站代替處理帳戶付款事宜，帳戶完成設定後就能使用網站服務。創建一個新帳戶需要完整與確實填寫所需的資料，使用者對於密碼有保密責任，且必須根據我們的聲明與政策使用本站，如果使用者未按預期方式使用，我們保有權力隨時停用您的帳戶。
      <br />
      <br />
      <br />
      <h2>一般帳戶：</h2>
      <br />
      在Civimender 蜥蜴代言 社群網站可以看到你所參與代言的商家商品、所剩餘時間與過去紀錄，帳戶的設定也在這裡進行。設定完成的帳戶可以進行選擇本站上任何一個企業商品製作作品，透過代言商品有「14天」的廣告分潤計費，得到的廣告分潤會匯入帳戶中所設定的「廣告分潤帳戶」，本站以每月
      5日結算費用並匯入設定帳戶。
      <br />
      <br />
      <br />
      <h2>企業帳戶：</h2>
      <br />
      在本站可以上架品牌、商品，供創作者選擇製作廣告影片，成為企業帳戶前必須先註冊一般帳戶，在進入一般帳戶的頁面中點擊右上方頭像「建立商家專頁」，您就可以在此專頁中管理您的上架資訊，完成付款後已上架的產品將會在本站曝光。
      <br />
      <br />
      <br />
      <h2>創作內容 Civimender Originals：</h2>
      <br />
      Civimender 蜥蜴代言 社群平台與創作者合作，授予各種影片作品的特定版權，您可以透過 YouTube
      、Facebook、Instagram 應用程式或在本站觀賞，而合作企業擁有
      作品的特定使用權。
      <br />
      <br />
      <br />
      <h3>發佈內容聲明：</h3>
      <br />
      您保證與同意，您向本站發佈的任何內容不會侵犯另一方的知識產權與內容發佈不會違反任何國家或地方法律。您還同意，您發佈的任何內容不會侵犯任何第三方權利
      -
      包括版權，商標，專利，宣傳權或其他知識產權，並且不會誹謗，誹謗，誹謗或侵犯隱私權或公開權。任何人或您向本站提交任何內容（翻譯、提問、評論或其他），您理解並同意本站可以在沒有任何要求或同意的情況下以任何理由使用它，並且本站可以編輯您的內容，只要原意得以保留。您同意您為本站提供以下許可，非獨家、且永久免版稅，與不可撤銷本站發佈內容，以便在全球任何媒體中使用您的內容，且以本站認為最適合全世界的任何方式使用內容。您必須遵守並熟悉聲明，如果您侵犯另一方知識產權或違反網站政策的方式發佈內容或濫用，我們有權利隨時刪除該內容或帳戶，並且您同意負全責來賠償本站所屬母公司。
      <br />
      <br />
      <br />
      <h3>發佈內容的許可聲明：</h3>
      <br />
      在Civimender 蜥蜴代言和我們管理或許可的任何其他網站上提供的內容由Civimender
      蜥蜴代言 社群平台擁有。除非網站上另有說明，否則音頻和視頻檔案只能用於個人和/或教育目的。
      除非使用聲明中另有規定，否則您同意在未經Civimender 蜥蜴代言 社群平台事先授權的情況下不發佈任何內容。
      <br />
      <br />
      <br />
    </div>

    <div style={{ marginBottom: 72, padding: 12 }}>
      <h1>如何透過Civimender 蜥蜴代言 合作企業賺取廣告收益</h1>
      <br />
      <p>
        您符合以下資格規定就可以透過在本站的功能賺取收益成年滿
        18歲，或有18歲以上的法定監護人可透過
        本站替您處理付款事宜製作的內容符合本站的政策與聲明
      </p>
      <br />
      <h2>方式：</h2>
      <br />
      <ol>
        <li>合作企業影片作品：</li>
        <br />
        <p>
          選擇本站上任何一個合作企業、產品製作作品並回傳審核，認證通過後公開作品於指定平台立即享有14天觀看點擊利潤，本站只接收一個獨立作品僅限於上傳於一個指定平台的回傳連結網址，不同指定平台的同作品一律不計入觀看點擊收益，建議創作者選擇最有利指定平台進行創作，該作品有一定程度的迴響則可額外獲得觀看點擊的累計紅利。本站與合作企業保證有上架後的第一支作品上線即計算
          14天的資訊頁面曝光，後續依企業主情況而定，廣告預算方案（點擊額度）可能隨時撤出，本站也會將該品牌下架
          頁面資訊關閉。
        </p>
        <p>
          說明：14天內的觀看點擊數將依照本站上不同會員的作品共同計算於同一合作企業後分別獲得計算觀看點擊收益，每個有效觀看數本站給予0.1元，使用作品上傳至
          Facebook
          的連結必須要是與本站同一作品；同在獎金計算時限內本站影片頁面討論區累計超過
          15則(不包含15則)訊息回應，可額外獲得同作品再計算每點擊價值
          0.1元觀看點擊紅利，作品的觀看點擊利潤與紅利本站設定於
          14天為期滿終止日結算並於次月5日匯入設定帳戶。
        </p>
        <br />
        <p>目前支援計算作品之點擊分潤的平台種類：Youtube、Facebook、Instagram</p>
        <p>目前支援計算作品分潤種類：
        <br />
          Youtube：影片作品觀看次數
          <br />
          Facebook：「影片」作品的觀看次數、「粉絲團」的貼文觸及數與影片觀看數
          <br />
          Instagram：「影片」作品的觀看次數、「商業帳戶」的貼文觸及數與影片觀看數
          </p>
        <br />
        <a href={Authrincom} target="_blank" rel="noopener noreferrer"><img src={Authrincom} style={{ width: '100%' }} /></a>
        <br />
        <br />
        <img src={Reve0105} height="200" />
        <img src={Reve0106} height="200" />
        <br />
        <br />
        <br />
        <li>合作企業作品導購方案：</li>
        <br />
        <p>
          選擇網站上任何一個企業、產品製作作品並回傳審核，通過後公開作品於指定平台並附上本站提供的訂單連結，完成後就享有該合作企業的訂單比率
          8%佣金，該作品有一定程度的迴響則可額外獲得訂單比率佣金。本站與合作企業的廣告預算上以抽成計費者
          預算方案將依企業主情況而定，廣告預算（點擊額度）可能隨時撤出，本站也會將該企業下架
          頁面資訊關閉。
        </p>
        <p>
          說明：合作企業提供訂單創作者可從中抽取一定比率作為佣金無時間限制直至合作企業將資訊下架，創作企業廣告預算上以抽成計費者作品不計觀看點擊數，每個表單連結於每月
          5日結算
          8%獎金匯入設定帳戶；如在本站影片頁面該作品討論區累計超過15則(不包含15則)訊息回應，則可額外獲得已結算的每個表單連結
          2%獎金，作品的佣金與紅利在本站設定於每月
          14日為期結算日並於次月5日匯入設定帳戶。
        </p>
        <br />
        <br />
        <li>製作合作企業圖片、貼文作品：</li>
        <br />
        <p>
        選擇本站上任何一個企業、產品製作圖片或貼文，同個作品在其他平台發佈後同樣上傳發佈於本站，發佈即同時計算14天廣告分潤，期滿須回傳驗證用錄影檔案證明觸及數或點擊數，廣告預算（點擊額度）可能隨時撤出，本站也會將該企業下架 頁面資訊關閉。
        </p>
        <p>
          說明：上傳發佈於其他平台與本站的圖片或貼文作品，發佈時即同時計算14天廣告分潤，在Facebook與Instagram的發文作品現金分潤計算由該作品的「觸及數」計算，每個有效觸及以0.1元計算，在Faceboo與Instagram的發文作品在期滿必須回傳「觸及數頁面錄影」驗證觸及數，不需錄影驗證，系統將自動計入分潤。
        </p> 
      </ol>
      <br />
      <br />
      <h2>您必須先完成所有步驟，我們才能核發您的第一筆廣告分潤款項。</h2>
      <br />
      <ol>
        <li>確認領取款項數目：<br />在「我的代言清單」中確認已完結的作品，系統將會結算統計金額至廣告分潤總數，請您確認款項</li>
        <br />
        <li>驗證身分：<br />我們可能會需要用您的姓名、地址或生日等資訊來驗證您的身分，以保障您確實收到款項。如果您需要進行這項驗證，在「代言清單」頁面確認完款項後，請至「帳號設定」頁面上傳您的身分證和欲收款帳戶本影像，如尚未驗證身份的帳戶我們將暫時不會匯出任何款項，直至我們能夠確認您的帳戶身份以確保收款程序</li>
        <br />
        <li>確認收款人姓名和地址：<br />在「帳戶設定」上傳的資訊請務必確認收款帳戶和收款人姓名正確無誤，當系統收到您「帳戶設定」中的資訊後將近處理您的款項。如需修改資訊，請回「帳號設定」頁面變更收款人姓名或更改收款帳戶，這變更系統將會重新一次驗證程序</li>
        <br />
        <li>透過電匯收款：<br />電匯是一種轉帳付款方式，可以將款項直接匯入您的銀行帳戶。希藝數位服務有限公司(以下簡稱本公司) 將會根據您提供的帳戶支付您的收益；款項會以電匯的方式匯到您的銀行，然後您就會在指定的銀行帳戶內收到款項。這是一種快速、簡單又安全的收款方式</li>
      </ol>
      <br />
      <p>
      本公司會以電匯的方式，將您的收益按照完結的作品分潤金額匯入銀行帳戶，最少提領金額必須超過100元才能提領您的分潤金額，您的分潤實際上會收到的金額將會預先扣除5%代收手續費與20元的轉帳手續費。若要收到款項，您必須在本站帳戶裡提供銀行帳戶資訊。需要提供的資訊皆用以確保帳戶與收款人能準確接收款項；詳情請參閱以上步驟。當您的帳戶設定中狀態為已完成身份的驗證，如資訊無誤系統會在25天內完成您的付款處理流程。我們就會將款項核發給您。如果您有任何疑問，請與您的銀行聯絡，並請他們提供電匯收款的相關說明。
      </p>
      <br />
      <p>
      「我的代言清單」會顯示作品收益的數目與時間。系統將會自動結算並統計分潤至總數。「帳戶簡介」頁面的「款項資訊」可追蹤已核發款項的狀態，以及帳戶的結算狀態，方便您查看已存入帳戶的收益或在處理中的款項。
      </p>
      <br />
      <h2>輸入銀行帳戶資訊</h2>
      <br />
      <p>
      您可以在本站「帳戶設定」頁面確認已設定的銀行帳戶資訊，請依照相關銀行文件詳實輸入各項資料。若要輸入銀行資訊驗證身份，請按照下列步驟進行：
      </p>
      <br />
      <ol>
      <li>登入註冊帳戶</li>
      <li>在「我的代言清單」頁面下方確認您已完結的作品，確認廣告分潤總數</li>
      <li>在「帳戶設定」下方進行上傳身分證和欲收款帳戶影像</li>
      <li>設定欄位輸入您同上傳的收款帳戶資訊，以方便您檢視您的收款帳戶</li>
      <li>輸入後按下[確認]</li>
      </ol>
    </div>
  </>
);

export default About;
