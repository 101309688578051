import { connect } from 'react-redux';

import LoginModal from './LoginModal';
import { saveUserInfo } from '../../redux/actions/user';

const mapDispatchToProps = dispatch => ({
  saveUserInfo: userInfo => {
    dispatch(saveUserInfo(userInfo));
  }
});

export default connect(null, mapDispatchToProps)(LoginModal);