import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import queryString from 'query-string';
import Compressor from 'compressorjs';

import styles from './Setting.module.scss';

const Setting = () => {
  const { id: userId } = useParams();
  const history = useHistory();
  const userInfo = useSelector((state) => state.user);
  const location = useLocation();
  const { section } = queryString.parse(location.search);

  const imageRef = React.useRef();
  const idCardRef = React.useRef();
  const bankAccountRef = React.useRef();

  const [imageUrl, setImageUrl] = React.useState('');
  const [displayName, setDisplayName] = React.useState('');
  const [email, setEmail] = React.useState('');

  const [intro, setIntro] = React.useState('');

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [bankCode, setBankCode] = React.useState('');
  const [bankAccount, setBankAccount] = React.useState('');
  const [idCardFileName, setIdCardFileName] = React.useState('');
  const [idCardFileUrl, setIdCardFileUrl] = React.useState('');
  const [bankAccountFileName, setBankAccountFileName] = React.useState('');
  const [bankAccountFileUrl, setBankAccountFileUrl] = React.useState('');

  React.useEffect(() => {
    setEmail(userInfo.email || '');
    setImageUrl(userInfo.imageUrl || '');
    setDisplayName(userInfo.displayName || '');

    setIntro(userInfo.intro || '');
    setBankCode(userInfo.bankCode || '');
    setBankAccount(userInfo.bankAccount || '');

    if (userInfo.idCardFileName) {
      firebase
        .storage()
        .ref()
        .child(`verifications/${userId}/${userInfo.idCardFileName}`)
        .getDownloadURL()
        .then((downloadURL) => {
          setIdCardFileUrl(downloadURL);
        });
    }
    if (userInfo.bankAccountFileName) {
      firebase
        .storage()
        .ref()
        .child(`verifications/${userId}/${userInfo.bankAccountFileName}`)
        .getDownloadURL()
        .then((downloadURL) => {
          setBankAccountFileUrl(downloadURL);
        });
    }
  }, [userInfo]);

  React.useEffect(() => {
    if (section === 'account') {
      document.getElementById('account').scrollIntoView();
    }
  }, [section]);

  return (
    <div style={{ marginBottom: 72, padding: '12px 12px 88px' }}>
      <div className={styles.name}>帳號設定</div>
      <div
        alt="使用者照片"
        className={styles.image}
        style={{
          backgroundImage: `url(${imageUrl || '/member.png'})`
        }}
      ></div>
      <button
        onClick={() => imageRef.current.click()}
        className={styles.change}
      >
        變更
      </button>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={imageRef}
        onChange={(e) => {
          const file = e.target.files[0];
          if (!file) return;
          new Compressor(file, {
            quality: 0.6,
            success: (result) => {
              const ext = result.name.split('.')[1];
              const ref = firebase
                .storage()
                .ref()
                .child(`users/${userId}.${ext}`);
              ref
                .put(result, {
                  cacheControl: 'public,max-age=86400'
                })
                .then((snapshot) => {
                  snapshot.ref.getDownloadURL().then((downloadURL) => {
                    setImageUrl(downloadURL);
                    imageRef.current.value = '';
                  });
                });
            },
            error(err) {
              console.log(err.message);
            }
          });
        }}
      />
      <div className={styles.label}>顯示名稱</div>
      <input
        className={styles.input}
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
      ></input>
      <div className={styles.label}>Email</div>
      <input className={styles.input} value={email} disabled />

      <div className={styles.label}>簡介</div>
      <textarea
        className={styles.input}
        value={intro}
        onChange={(e) => setIntro(e.target.value)}
        rows="10"
      ></textarea>
      <button
        className={styles.button}
        onClick={() => {
          const userRef = firebase.database().ref(`users/${userId}`);
          userRef.update(
            {
              imageUrl,
              displayName,
              intro
            },
            (error) => {
              if (error) console.error(error);
              else history.push(`/user/${userId}`);
            }
          );
        }}
      >
        儲存
      </button>

      <br />
      <div className={styles.wall}></div>

      <div className={styles.name}>變更密碼</div>
      <div className={styles.label}>輸入舊密碼</div>
      <input
        className={styles.input}
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        type="password"
      ></input>
      <div className={styles.label}>新密碼</div>
      <input
        className={styles.input}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        type="password"
      ></input>
      <div className={styles.label}>確認新密碼</div>
      <input
        className={styles.input}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        type="password"
      ></input>
      <button
        className={styles.button}
        onClick={() => {
          if (newPassword !== confirmPassword) {
            window.alert('兩次輸入密碼不同，請重新輸入');
            return;
          }
          const { currentUser } = firebase.auth();
          firebase
            .auth()
            .signInWithEmailAndPassword(currentUser.email, oldPassword)
            .then(() => {
              currentUser
                .updatePassword(newPassword)
                .then(function () {
                  window.alert('新密碼修改完成，請重新登入');
                  firebase.auth().signOut();
                  history.push('/');
                })
                .catch(function (error) {
                  console.log(error);
                  window.alert('新密碼不合法，請重新輸入');
                  return;
                });
            })
            .catch(function (error) {
              console.log(error);
              window.alert('舊密碼有誤，請重新輸入');
              return;
            });
        }}
      >
        儲存
      </button>

      <br />
      <div className={styles.wall}></div>

      <div className={styles.name} id="account">
        廣告分潤帳戶
      </div>
      <div className={styles.label}>銀行帳戶</div>
      <input
        className={styles.input}
        value={bankAccount}
        onChange={(e) => setBankAccount(e.target.value)}
      />
      <div className={styles.label}>銀行代碼</div>
      <input
        className={styles.input}
        value={bankCode}
        onChange={(e) => setBankCode(e.target.value)}
      />

      <div className={styles.label}>上傳身分證</div>
      <div
        className={styles.placeholder}
        style={{ backgroundImage: `url(${idCardFileUrl})` }}
        onClick={() => {
          idCardRef.current.click();
        }}
      >
        {!idCardFileUrl && '點擊以上傳身分證'}
      </div>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={idCardRef}
        onChange={(e) => {
          const file = e.target.files[0];
          if (!file) return;
          new Compressor(file, {
            quality: 0.6,
            success: (result) => {
              firebase
                .storage()
                .ref()
                .child(`verifications/${userId}/${result.name}`)
                .put(result, {
                  cacheControl: 'public,max-age=86400'
                })
                .then((snapshot) => {
                  snapshot.ref.getDownloadURL().then((downloadURL) => {
                    setIdCardFileUrl(downloadURL);
                    setIdCardFileName(result.name);
                    if (idCardRef.current) {
                      idCardRef.current.value = '';
                    }
                  });
                });
            },
            error(err) {
              console.log(err.message);
            }
          });
        }}
      />

      <div className={styles.label}>上傳銀行存摺</div>
      <div
        className={styles.placeholder}
        style={{ backgroundImage: `url(${bankAccountFileUrl})` }}
        onClick={() => {
          bankAccountRef.current.click();
        }}
      >
        {!bankAccountFileUrl && '點擊以上傳銀行存摺'}
      </div>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={bankAccountRef}
        onChange={(e) => {
          const file = e.target.files[0];
          if (!file) return;
          new Compressor(file, {
            quality: 0.6,
            success: (result) => {
              firebase
                .storage()
                .ref()
                .child(`verifications/${userId}/${result.name}`)
                .put(result, {
                  cacheControl: 'public,max-age=86400'
                })
                .then((snapshot) => {
                  snapshot.ref.getDownloadURL().then((downloadURL) => {
                    setBankAccountFileUrl(downloadURL);
                    setBankAccountFileName(result.name);
                    if (bankAccountRef.current) {
                      bankAccountRef.current.value = '';
                    }
                  });
                });
            },
            error(err) {
              console.log(err.message);
            }
          });
        }}
      />
      <button
        className={styles.button}
        onClick={() => {
          const userRef = firebase.database().ref(`users/${userId}`);
          userRef.update(
            {
              bankCode,
              bankAccount,
              idCardFileName,
              bankAccountFileName
            },
            (error) => {
              if (error) console.error(error);
              else history.push(`/user/${userId}`);
            }
          );
        }}
      >
        儲存
      </button>
    </div>
  );
};

Setting.propTypes = {};

Setting.defaultProps = {};

export default React.memo(Setting);
