import { connect } from 'react-redux';

import Header from './Header';
import { saveUserInfo } from '../../redux/actions/user';

const mapStateToProps = state => ({
  userInfo: state.user
});

const mapDispatchToProps = dispatch => ({
  saveUserInfo: userInfo => {
    dispatch(saveUserInfo(userInfo));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);