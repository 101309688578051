import { SAVE_WITHDRAWALS } from '../actions/withdrawals';

export default (state = {}, action) => {
  switch (action.type) {
    case SAVE_WITHDRAWALS:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};