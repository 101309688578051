import React from 'react';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import Compressor from 'compressorjs';
import { FormCheck, Form } from 'react-bootstrap';

import styles from './NewPost.module.scss';
import fbPng from './fb.png';
import igPng from './ig.png';
import ytPng from './yt.png';
import ProgressBarModal from '../ProgressBarModal/ProgressBarModal';
import { getYoutubeEmbedUrl } from '../../utils/utils';

const settings = {
  infinite: false,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const NewPost = ({
  history,
  cancel,
  creatingType,
  imageUrls: urls,
  userProductId,
  productName,
  videoUrl: url,
  close,
  title: postTitle,
  value: postValue,
  hideUploadImage
}) => {
  const userInfo = useSelector((state) => state.user);
  const tags = useSelector((state) => {
    const { tags } = state;
    return Object.keys(tags)
      .filter((tagId) => tags[tagId].userId === userInfo.id)
      .map((tagId) => ({
        ...tags[tagId],
        id: tagId
      }));
  });
  const [value, setValue] = React.useState(postValue || '');
  const [title, setTitle] = React.useState(postTitle || '');
  const [imageUrls, setImageUrls] = React.useState(urls || []);

  const [postUrl, setPostUrl] = React.useState('');
  const [videoUrl, setVideoUrl] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);

  const [isUploading, setIsUploading] = React.useState(false);

  const [isUploadingFile, setIsUploadingFile] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [isTagAnnouncement, setIsTagAnnouncement] = React.useState(false);
  const [selectedTagId, setSelectedTagId] = React.useState('');

  const [files, setFiles] = React.useState([]);

  const imageRef = React.createRef();
  return (
    <>
      <div className={styles.newPost}>
        <p style={{ color: '#a2c198' }} onClick={cancel}>
          <FontAwesomeIcon icon={faTimes} size="1x" color="#a2c198" /> 取消
        </p>
        {creatingType === 'post' && <p>建立貼文</p>}
        {creatingType === 'image' && <p>建立照片貼文</p>}
        {creatingType === 'video' && <p>發佈影片</p>}
        {!isUploading &&
          url &&
          (getYoutubeEmbedUrl(url) ? (
            <iframe
              src={getYoutubeEmbedUrl(url)}
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ width: '100%' }}
            />
          ) : (
            <video controls autoPlay style={{ width: '100%' }}>
              <source src={url}></source>
            </video>
          ))}
        {!isUploading && imageUrls.length > 0 && (
          <Slider {...settings}>
            {imageUrls.map((imageUrl, index) => (
              <img
                key={index}
                alt="貼文照片"
                className={styles.image}
                src={imageUrl}
              />
            ))}
          </Slider>
        )}
        {isUploading && (
          <div className={styles.loading}>
            <ReactLoading type="spinningBubbles" color="#2E8B57" />
          </div>
        )}
        <input
          className={styles.input}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="請輸入標題"
        />
        {creatingType === 'video' && userProductId && (
          <>
            <input
              className={styles.input}
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              placeholder={`${
                productName === 'CiviPost' ? '若有原影片網址' : ''
              }請輸入連結`}
              style={{
                width: 'calc(100% - 110px)',
                display: 'inline-block'
              }}
            />
            <button
              className={styles.publish}
              onClick={() => {
                setShowModal(true);
              }}
            >
              取得連結
            </button>
          </>
        )}
        {creatingType === 'post' && (
          <textarea
            className={styles.textArea}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder="請輸入貼文內容"
          />
        )}
        {creatingType === 'video' && (
          <textarea
            className={styles.textArea}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="請輸入影片描述"
          />
        )}
        {productName !== 'CiviPost' && (
          <p>
            如要賺取「觸及分潤」請貼上Facebook或Instagram上已發佈的原貼文網址，14日後請在「代言清單」頁面回傳驗證錄影檔
          </p>
        )}
        {creatingType !== 'video' && productName !== 'CiviPost' && (
          <input
            className={styles.input}
            value={postUrl}
            onChange={(e) => setPostUrl(e.target.value)}
            placeholder={`${
              productName === 'CiviPost' ? '若有原貼文網址' : ''
            }請輸入貼文連結`}
            style={
              productName !== 'CiviPost'
                ? {
                    width: 'calc(100% - 110px)',
                    display: 'inline-block'
                  }
                : {}
            }
          />
        )}
        {productName !== 'CiviPost' && creatingType !== 'video' && (
          <button
            className={styles.publish}
            onClick={() => {
              setShowModal(true);
            }}
          >
            取得連結
          </button>
        )}
        {productName === 'CiviPost' && (
          <FormCheck>
            <FormCheck.Input
              type="checkbox"
              checked={isTagAnnouncement}
              onChange={(e) => {
                const checked = e.target.checked;
                setIsTagAnnouncement(checked);
                if (checked && tags[0]) {
                  setSelectedTagId(tags[0].id);
                } else if (!checked) {
                  setSelectedTagId('');
                }
              }}
            />
            <FormCheck.Label>設為標籤公告</FormCheck.Label>
          </FormCheck>
        )}
        <Form.Control
          as="select"
          value={selectedTagId}
          onChange={(e) => setSelectedTagId(e.target.value)}
        >
          {!isTagAnnouncement && <option value="">公開</option>}
          {productName === 'CiviPost' &&
            tags.map((tag) => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
        </Form.Control>
        <button
          className={styles.publish}
          onClick={async () => {
            const isNotLoggedIn = !userInfo.id;

            if (isNotLoggedIn) return;

            const posts = firebase.firestore().collection('posts');
            const isEditing = !!userProductId;
            if (isEditing) {
              const post = posts.doc(userProductId);
              switch (creatingType) {
                case 'video': {
                  if (!title.trim() || !videoUrl.trim()) {
                    window.alert('標題或連結不可為空白');
                    return;
                  }
                  setIsUploading(true);
                  setIsUploadingFile(true);
                  const uploadTask = firebase
                    .storage()
                    .ref()
                    .child(`videos/${userProductId}/${files[0].name}`)
                    .put(files[0], {
                      cacheControl: 'public,max-age=86400'
                    });

                  const promise = new Promise((resolve) => {
                    uploadTask.on(
                      'state_changed',
                      (snapshot) => {
                        setProgress(
                          (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100
                        );
                      },
                      () => {},
                      () => {
                        uploadTask.snapshot.ref
                          .getDownloadURL()
                          .then((downloadURL) => {
                            resolve(downloadURL);
                          });
                      }
                    );
                  });
                  const fileUrl = await promise;
                  setIsUploading(false);
                  setIsUploadingFile(false);
                  await post.update({
                    updated: Date.now(),
                    videoUrl: videoUrl.trim(),
                    title: title.trim(),
                    type: 'video',
                    fileUrl,
                    value
                  });
                  break;
                }
                case 'image':
                case 'post': {
                  if (!title.trim()) {
                    window.alert('標題不可為空白');
                    return;
                  }
                  if (!postUrl.trim()) {
                    window.alert('連結不可為空白');
                    return;
                  }
                  if (creatingType === 'post' && !value.trim()) {
                    window.alert('內文不可為空白');
                    return;
                  }
                  if (creatingType === 'image' && imageUrls.length === 0) {
                    window.alert('請上傳圖片');
                    return;
                  }

                  setIsUploading(true);
                  setIsUploadingFile(true);
                  const uploadFiles = Array.prototype.map.call(
                    files,
                    (file) => {
                      return new Promise((resolve, reject) => {
                        new Compressor(file, {
                          quality: 0.6,
                          success: (result) => {
                            const uploadTask = firebase
                              .storage()
                              .ref()
                              .child(`posts/${userProductId}/${result.name}`)
                              .put(result, {
                                cacheControl: 'public,max-age=86400'
                              });
                            uploadTask.on(
                              'state_changed',
                              (snapshot) => {
                                setProgress(
                                  (snapshot.bytesTransferred /
                                    snapshot.totalBytes) *
                                    100
                                );
                              },
                              () => {},
                              () => {
                                uploadTask.snapshot.ref
                                  .getDownloadURL()
                                  .then((downloadURL) => {
                                    resolve(downloadURL);
                                  });
                              }
                            );
                          },
                          error(err) {
                            reject(err);
                          }
                        });
                      });
                    }
                  );
                  const urls = await Promise.all(uploadFiles);
                  await post.update({
                    title,
                    value,
                    imageUrls: urls,
                    type: creatingType,
                    updated: Date.now(),
                    postUrl: postUrl.trim()
                  });
                  setIsUploading(false);
                  setIsUploadingFile(false);
                  break;
                }
              }
            } else {
              switch (creatingType) {
                case 'video': {
                  if (!title.trim() || !value.trim()) {
                    window.alert('標題或內文不可為空白');
                    return;
                  }

                  const post = {
                    title,
                    value,
                    type: creatingType,
                    updated: Date.now(),
                    created: Date.now(),
                    userId: userInfo.id,
                    public: isTagAnnouncement
                  };
                  if (selectedTagId) post.tagId = selectedTagId;
                  await posts.add(post);
                  break;
                }
                case 'image': {
                  if (!title.trim()) {
                    window.alert('標題不可為空白');
                    return;
                  }
                  const post = {
                    title,
                    value,
                    imageUrls,
                    type: creatingType,
                    updated: Date.now(),
                    created: Date.now(),
                    userId: userInfo.id,
                    postUrl: postUrl.trim(),
                    public: isTagAnnouncement
                  };
                  if (selectedTagId) post.tagId = selectedTagId;
                  await posts.add(post);
                  break;
                }
                case 'post': {
                  if (!title.trim() || !value.trim()) {
                    window.alert('標題或內文不可為空白');
                    return;
                  }
                  const post = {
                    title,
                    value,
                    imageUrls,
                    type: creatingType,
                    updated: Date.now(),
                    created: Date.now(),
                    userId: userInfo.id,
                    postUrl: postUrl.trim(),
                    public: isTagAnnouncement
                  };
                  if (selectedTagId) post.tagId = selectedTagId;
                  await posts.add(post);
                  break;
                }
              }
            }

            close();

            switch (creatingType) {
              case 'post':
                history.push('/?tab=3');
                break;
              case 'image':
                history.push('/?tab=2');
                break;
              case 'video':
                history.push('/?tab=1');
                break;
              default:
            }
          }}
        >
          發佈
        </button>
        {creatingType === 'video' && userProductId && !url && (
          <>
            {files[0]?.name || (
              <button
                className={styles.publish}
                onClick={() => {
                  imageRef.current.click();
                }}
              >
                回傳影片原檔
              </button>
            )}
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={imageRef}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file.size > 1024 * 1024 * 1024) {
                  window.alert(
                    '檔案過大，請重新上傳小於 1 GB 的影片或是直接發佈'
                  );
                  return;
                }
                setFiles([file]);
                if (imageRef.current) {
                  imageRef.current.value = '';
                }
              }}
            />
            {isUploadingFile && <ProgressBarModal progress={progress} />}
          </>
        )}
        {creatingType !== 'video' && !hideUploadImage && (
          <>
            <button
              className={styles.publish}
              onClick={() => {
                imageRef.current.click();
              }}
            >
              {imageUrls.length > 0 ? '修改圖片' : '上傳圖片'}
            </button>
            <input
              multiple
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={imageRef}
              onChange={(e) => {
                const urls = Array.prototype.map.call(e.target.files, (file) =>
                  URL.createObjectURL(file)
                );
                setFiles(Array.from(e.target.files));
                setImageUrls(urls);
                if (imageRef.current) {
                  imageRef.current.value = '';
                }
              }}
            />
            {isUploadingFile && <ProgressBarModal progress={progress} />}
          </>
        )}
      </div>
      {showModal && (
        <div
          className={styles.modalWrapper}
          onClick={(e) => {
            if (e.target === e.currentTarget) setShowModal(false);
          }}
        >
          <div className={styles.modal}>
            <div className={styles.title}>選擇您的廣告目標平台上傳</div>
            <div className={styles.logos}>
              <div
                className={styles.logo}
                onClick={() => {
                  window.open('https://youtube.com');
                }}
              >
                <img src={ytPng} />
                YouTube
              </div>
              <div
                className={styles.logo}
                onClick={() => {
                  window.open('https://instagram.com');
                }}
              >
                <img src={igPng} />
                Instagram
              </div>
              <div
                className={styles.logo}
                onClick={() => {
                  window.open('https://facebook.com');
                }}
              >
                <img src={fbPng} />
                Facebook
              </div>
            </div>
            <div className={styles.description}>
              建議您選擇能獲得最多點擊平台與方式上傳
              <br />
              我們將依照您上傳的平台作品計算廣告分潤
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default withRouter(NewPost);
