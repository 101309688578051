import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import linkifyStr from 'linkifyjs/string';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './UserProduct.module.scss';
import iconArrowRight from '../../images/iconArrowRight.svg';
import LoginModalContainer from '../LoginModal/LoginModalContainer';
import Likes from '../Likes/Likes';
import iconShare from '../../images/share.svg';
import iconContext from '../../images/context.svg';
import iconVerified from '../../images/verified.svg';
import { getYoutubeEmbedUrl } from '../../utils/utils';

const settings = {
  infinite: false,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const getTimeAgo = (timestamp) => {
  const diff = Date.now() - timestamp;
  const msInWeek = 7 * 24 * 60 * 60 * 1000;
  const msInDay = 24 * 60 * 60 * 1000;
  const msInHour = 60 * 60 * 1000;
  const msInMinute = 60 * 1000;

  if (diff > msInWeek)
    return new Date(timestamp).toISOString().slice(0, 10).replace(/-/g, '/');
  const daysAgo = diff / msInDay;
  if (daysAgo >= 1) return `${Math.floor(daysAgo)} 天前`;
  const hoursAgo = diff / msInHour;
  if (hoursAgo >= 1) return `${Math.floor(hoursAgo)} 小時前`;
  const minutesAgo = diff / msInMinute;
  if (minutesAgo >= 1) return `${Math.floor(minutesAgo)} 分鐘前`;
  return '不久前';
};

const UserProduct = () => {
  const { id } = useParams();
  const history = useHistory();
  const userInfo = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const userProduct = useSelector((state) => state.userProducts[id] || {});
  const brand = useSelector((state) => state.brands[userProduct.brandId] || {});
  const product = useSelector(
    (state) => state.products[userProduct.productId] || {}
  );
  const user = users[userProduct.userId] || {};
  const filteredComments = useSelector((state) =>
    Object.keys(state.comments)
      .map((commentId) => ({
        ...state.comments[commentId],
        id: commentId,
      }))
      .filter((comment) => comment.userProductId === id)
  );
  const [inputValue, setInputValue] = React.useState('');
  const [editingCommentId, setEditingCommentId] = React.useState();
  const [showLoginModal, setShowLoginModal] = React.useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        {userProduct.imageUrl && (
          <div
            className={styles.video}
            style={{ backgroundImage: `url(${userProduct.imageUrl})` }}
          />
        )}
        {userProduct.imageUrls && (
          <Slider {...settings}>
            {userProduct.imageUrls.map((imageUrl, index) => (
              <img
                key={index}
                alt="貼文照片"
                className={styles.video}
                src={imageUrl}
              />
            ))}
          </Slider>
        )}
        {(userProduct.fileUrl || getYoutubeEmbedUrl(userProduct.videoUrl)) && (
          <div className={styles.video}>
            {userProduct.fileUrl ? (
              <video controls autoPlay>
                <source src={userProduct.fileUrl}></source>
              </video>
            ) : (
              <iframe
                src={getYoutubeEmbedUrl(userProduct.videoUrl)}
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
        )}
        <h1 className={styles.title}>{userProduct.title}</h1>
        <p
          className={styles.value}
          dangerouslySetInnerHTML={{
            __html: linkifyStr(userProduct.value || '', {
              attributes: {
                rel: 'noopener noreferrer',
                style: 'text-decoration: underline; color: #2E8B57;',
              },
              nl2br: true,
            }),
          }}
        />
        <div className={styles.info}>
          <div
            className={styles.user}
            onClick={() => {
              if (userProduct.userId) {
                history.push(`/user/${userProduct.userId}`);
              }
            }}
          >
            <div
              className={styles.userPhoto}
              style={{
                backgroundImage: `url(${user.imageUrl || '/member.png'})`,
              }}
            />
            <div className={styles.userName}>{user.displayName}</div>
          </div>
          <div
            style={{ display: 'flex', width: 60, justifyContent: 'flex-end' }}
          >
            <OverlayTrigger
              trigger="click"
              overlay={
                <Tooltip>
                  <div className={styles.tooltip}>
                    {(userProduct.originalUrl ||
                      userProduct.videoUrl ||
                      userProduct.postUrl) && (
                      <div
                        onClick={() => {
                          window.open(
                            userProduct.originalUrl ||
                              userProduct.videoUrl ||
                              userProduct.postUrl
                          );
                        }}
                        className={styles.openLink}
                      >
                        <img
                          src={iconArrowRight}
                          className={styles.arrow}
                          width="23"
                        ></img>
                        開啟連結
                      </div>
                    )}
                    <div
                      onClick={() => {
                        window.FB.ui({
                          method: 'share',
                          href: `https://www.civimender.com/user-products/${id}`,
                        });
                      }}
                      className={styles.share}
                    >
                      <img
                        src={iconShare}
                        className={styles.arrow}
                        width="16"
                      ></img>
                      分享
                    </div>
                  </div>
                </Tooltip>
              }
              rootClose
              rootCloseEvent="click"
            >
              <div className={styles.contextButton}>
                <img src={iconContext} width="20" />
              </div>
            </OverlayTrigger>
            <Likes userProductId={id} />
          </div>
        </div>
        <div className={styles.moreInfo}>
          <div
            className={styles.brand}
            onClick={() => {
              history.push(`/brands/${userProduct.brandId}`);
            }}
            style={{ backgroundImage: `url(${brand.imageUrl})` }}
          />
          {userProduct.productId && (
            <div
              className={styles.product}
              onClick={() => {
                history.push(
                  `/brands/${userProduct.brandId}/products/${userProduct.productId}`
                );
              }}
              style={{ backgroundImage: `url(${product.imageUrl})` }}
            />
          )}
          {brand.webUrl && (
            <a href={brand.webUrl} target="_blank" rel="noopener noreferrer">
              官方網站
            </a>
          )}
          {brand.facebookUrl && (
            <a
              href={brand.facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          )}
          {brand.instagramUrl && (
            <a
              href={brand.instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          )}
        </div>
        <div className={styles.forum}>
          <div className={styles.inputWrapper}>
            <input
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              placeholder="輸入留言"
            />
            <button
              onClick={() => {
                if (!userInfo.id) {
                  setShowLoginModal(true);
                  return;
                }

                firebase.database().ref('comments').push().set({
                  userProductId: id,
                  userId: userInfo.id,
                  created: Date.now(),
                  content: inputValue,
                });
                setInputValue('');
              }}
            >
              送出
            </button>
          </div>
          {filteredComments
            .sort((a, b) => a.created - b.created)
            .map((comment, index) => {
              const user = Object.values(users).find(
                (user) => user.id === comment.userId
              );
              return (
                <div key={comment.id} className={styles.comment}>
                  {user && (
                    <div
                      className={styles.userImage}
                      style={{ backgroundImage: `url(${user.imageUrl})` }}
                    />
                  )}
                  <div className={styles.commentInfo}>
                    <div className={styles.userName}>
                      {user ? user.displayName : ''}
                      {user.verified && (
                        <img src={iconVerified} width="12" />
                      )}{' '}
                      <span>{getTimeAgo(comment.created)}</span>
                    </div>
                    {comment.id === editingCommentId ? (
                      <>
                        <input defaultValue={comment.content} />
                        <button
                          onClick={(e) => {
                            firebase
                              .database()
                              .ref(`comments/${comment.id}`)
                              .update({
                                updated: Date.now(),
                                content: e.target.previousSibling.value,
                              });
                            setEditingCommentId();
                          }}
                        >
                          確認
                        </button>
                        <button
                          onClick={() => {
                            setEditingCommentId();
                          }}
                        >
                          取消
                        </button>
                      </>
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: linkifyStr(comment.content, {
                            attributes: {
                              rel: 'noopener noreferrer',
                              style: 'text-decoration: underline;',
                            },
                          }),
                        }}
                      />
                    )}
                  </div>
                  <div className={styles.floor}>
                    {Object.keys(filteredComments).length - index} 樓
                  </div>
                  {comment.userId === userInfo.id &&
                  comment.id !== editingCommentId ? (
                    <OverlayTrigger
                      trigger="click"
                      overlay={
                        <Tooltip>
                          <span
                            onClick={() => {
                              setEditingCommentId(comment.id);
                            }}
                          >
                            編輯
                          </span>
                          <span
                            onClick={() => {
                              firebase
                                .database()
                                .ref(`comments/${comment.id}`)
                                .remove();
                            }}
                          >
                            刪除
                          </span>
                        </Tooltip>
                      }
                      rootClose
                      rootCloseEvent="click"
                    >
                      <div className={styles.contextButton}>
                        <FontAwesomeIcon
                          icon={faEllipsisV}
                          color="gray"
                          width={4}
                        />
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <div className={styles.contextButton} />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {showLoginModal && (
        <LoginModalContainer closeLoginModal={() => setShowLoginModal(false)} />
      )}
    </>
  );
};

UserProduct.propTypes = {};

UserProduct.defaultProps = {};

export default React.memo(UserProduct);
