import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';

import styles from './ProgressBarModal.module.scss';

const ProgressBarModal = ({ progress }) => {
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.progressBarWrapper}>
        <ProgressBar now={progress} animated />
      </div>
    </div>
  );
};

ProgressBarModal.propTypes = {
  progress: PropTypes.number.isRequired,
};

ProgressBarModal.defaultProps = {};

export default React.memo(ProgressBarModal);
