import React from 'react';
import styles from './RegisteredBrand.module.scss';
const RegisteredBrand = () => (
  <div style={{ marginBottom: 72, padding: 12 }}>
    <h1>品牌代言專案區</h1>
    <br />
    <br />
    <br />
    <br />
    <br />
    <p className={styles.center}>品牌代言專案即將發佈</p>
  </div>
);

export default RegisteredBrand;
