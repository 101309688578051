import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import styles from './GetItem.module.scss';
import pngUbereats from './ubereats.png';
import pngFoodpanda from './foodpanda.png';
import svgNearMe from './near_me-black-18dp.svg';
import svgCall from './call-black-18dp.svg';
import logo from './logo.png';
import { getMessengerUrl } from '../../utils/utils';

const GetItem = (props) => {
  const { brandId, productId, closeModal, onSkip } = props;
  const product = useSelector((state) => state.products[productId] || {});
  const brand = useSelector((state) => state.brands[brandId] || {});

  return (
    <div
      className={styles.modalWrapper}
      onClick={(e) => {
        if (e.target === e.currentTarget) closeModal();
      }}
    >
      <img src={logo} className={styles.logo} />
      <div className={styles.modal}>
        <div className={styles.title}>取得物品</div>
        <img
          src={product.imageUrl}
          width="160"
          style={{ maxHeight: 170, objectFit: 'contain' }}
        />
        <div style={{ fontWeight: 'bold' }}>{product.name}</div>
        <div className={styles.icons}>
          {brand.buyUrl && (
            <a href={brand.buyUrl} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faCartArrowDown} size="2x" />
            </a>
          )}
          {brand.locationUrl && (
            <a
              href={brand.locationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={svgNearMe} height="32" style={{ marginLeft: 4 }} />
            </a>
          )}
          {brand.ubereatsUrl && (
            <a
              href={brand.ubereatsUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={pngUbereats} height="32" style={{ marginLeft: 4 }} />
            </a>
          )}
          {brand.foodpandaUrl && (
            <a
              href={brand.foodpandaUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={pngFoodpanda} height="32" style={{ marginLeft: 4 }} />
            </a>
          )}
        </div>
        <div style={{ fontSize: 12 }}>
          可線上聯繫或到實體地址取得產品協助創作，業主可能透過「試用、租借或需自費購入產品」等方式提供產品協助創作
        </div>
        <div className={styles.icons}>
          {brand.facebookUrl && (
            <a
              href={getMessengerUrl(brand.facebookUrl)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookMessenger}
                size="2x"
                color="#0084FF"
              />
            </a>
          )}
          {brand.phone && (
            <a href={`tel:${brand.phone}`}>
              <img src={svgCall} height="32" style={{ marginLeft: 16 }} />
            </a>
          )}
        </div>
        <button
          onClick={() => {
            closeModal();
            if (typeof onSkip === 'function') {
              onSkip();
            }
          }}
        >
          跳過
        </button>
      </div>
    </div>
  );
};

export default React.memo(GetItem);
