import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faPlusCircle,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Compressor from 'compressorjs';

import styles from './Brand.module.scss';
import Product from './Product';
import Fire from './civicoin02-01.png';
import WebIcon from './outlinksicon-01.svg';
import FacebookIcon from './outlinksicon-02.svg';
import InstagramIcon from './outlinksicon-03.svg';
import Post from '../Post/Post';
import GetItem from '../GetItem/GetItem';
import ProductForm from '../ProductForm/ProductForm';

/*
const renderTagDescription = (tag) => {
  switch (tag) {
    case '一般':
      return '無任何審核條件規範，在不違反Civimender網站基本原則即可隨興創作發佈作品。';
    case '需審核':
      return '須遵守該品牌專頁所列審核項目，審核後即可公開作品。';
    case '觀看點擊分潤':
      return '使用點擊觀看計數供創作者獲得廣告分潤。';
    case '導購分潤':
      return '使用專用的導購連結，透過連結的交易單數獲得廣告分潤。';
    default:
      return null;
  }
};
*/

const Brand = () => {
  const inputRef = React.useRef();
  const { id: brandId } = useParams();
  const posts = useSelector((state) => state.userProducts);
  const brand = useSelector((state) => state.brands[brandId] || {});
  const [editingProductId, setEditingProductId] = useState();
  const editingProduct = useSelector(
    (state) => state.products[editingProductId] || {}
  );
  const [showGetItem, setShowGetItem] = useState(false);
  const [productId, setProductId] = useState();
  const [showProductForm, setShowProductForm] = useState(false);
  const history = useHistory();
  const userInfo = useSelector((state) => state.user);
  const likes = useSelector((state) => state.likes);

  const isMine = brand.userId && brand.userId === userInfo.id;

  const [columns, setColumns] = React.useState(
    window.innerWidth >= 768 ? 2 : 1
  );

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) setColumns(2);
      else setColumns(1);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {
    imageUrl,
    products,
    description,
    webUrl,
    facebookUrl,
    instagramUrl,
    googlemapUrl,
    points,
    userId,
    cover,
    title
  } = brand;

  const getConsumedPoints = () => {
    const totalLikes = Object.keys(likes).filter((likeId) => {
      return likes[likeId].brandId === brandId;
    }).length;

    const brandUserProducts = Object.keys(posts)
      .map((postId) => ({
        id: postId,
        ...posts[postId]
      }))
      .filter((post) => {
        return (
          post.brandId === brandId &&
          post.type &&
          ![
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '-M9IgIsZ_kRJ0YTRBGUf'
          ].includes(post.id)
        );
      });

    const totalView = brandUserProducts.reduce(
      (view, brandUserProduct) => view + (brandUserProduct.view || 0),
      0
    );

    const totalReach = brandUserProducts.reduce(
      (reach, brandUserProduct) => reach + (brandUserProduct.reach || 0),
      0
    );

    return totalLikes + totalView + totalReach;
  };

  const filteredPosts = Object.keys(posts)
    .reverse()
    .filter((postId) => {
      return posts[postId].brandId === brandId && posts[postId].type;
    });

  return (
    <div className={styles.brand}>
      <div
        className={styles.cover}
        style={{
          backgroundImage: `url(${cover})`,
          cursor: userId && userId === userInfo.id ? 'pointer' : 'default'
        }}
        onClick={() => {
          if (userId && userId === userInfo.id) {
            inputRef.current.click();
          }
        }}
      >
        {!cover && userId && userId === userInfo.id && '＋自訂背景圖片'}
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            const file = e.target.files[0];
            if (!file) return;
            new Compressor(file, {
              quality: 0.6,
              success: (result) => {
                firebase
                  .storage()
                  .ref()
                  .child(`brands/${brandId}/cover`)
                  .put(result, {
                    cacheControl: 'public,max-age=86400'
                  })
                  .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                      firebase.database().ref(`brands/${brandId}`).update({
                        cover: downloadURL
                      });
                    });
                  });
              },
              error(err) {
                console.log(err.message);
              }
            });
          }}
        />
      </div>
      {userId && userId === userInfo.id && (
        <div className={styles.buttons}>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => history.push(`/campaign-form?brandId=${brandId}`)}
          />
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={() => {
              const result = window.confirm('確定要刪除商家頁面嗎？');
              if (!result) return;

              firebase
                .database()
                .ref(`brands/${brandId}`)
                .delete()
                .then(() => {
                  window.alert('刪除成功');
                  history.push('/my-brands');
                });
            }}
          />
        </div>
      )}
      {Object.keys(brand).length > 0 && (
        <div className={styles.info}>
          <div className={styles.left}>
            <div className={styles.placeholder}>
              {imageUrl ? (
                <img width="120" src={imageUrl} />
              ) : (
                <ReactLoading type="spinningBubbles" color="#2E8B57" />
              )}
            </div>
            <div className={styles.name}>{title}</div>
            <div className={styles.links}>
              {webUrl && (
                <a href={webUrl} target="_blank" rel="noopener noreferrer">
                  <img src={WebIcon} width="24" />
                </a>
              )}
              {facebookUrl && (
                <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
                  <img src={FacebookIcon} />
                </a>
              )}
              {instagramUrl && (
                <a
                  href={instagramUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={InstagramIcon} />
                </a>
              )}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.tags}>
              {Object.keys(brand.tags || {}).map((tag) => (
                <span className={styles.tag} key={tag}>
                  {tag}
                </span>
              ))}
            </div>
            <div className={styles.points}>
              <img width="24" src={Fire} />
              &nbsp;CIVI點數
              <br />
              <span>
                {points
                  ? points - getConsumedPoints() <= 0
                    ? '待更新'
                    : points - getConsumedPoints()
                  : '待更新'}
              </span>
              {isMine && (
                <button
                  className={styles.fill}
                  onClick={() => {
                    history.push(`/plans?brandId=${brandId}`);
                  }}
                >
                  儲值點數
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      {googlemapUrl && (
        <iframe
          src={googlemapUrl}
          width="375"
          height="100"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      )}
      <div className={styles.products}>
        {products &&
          Object.keys(products).map((productId) => (
            <div
              key={productId}
              onClick={() => {
                if (userInfo.id) {
                  setShowGetItem(true);
                  setProductId(productId);
                } else {
                  history.push(`/brands/${brandId}/products/${productId}`);
                }
              }}
            >
              <Product
                productId={productId}
                deleteProduct={() => {
                  const result = window.confirm('確定要刪除商品嗎？');
                  if (!result) return;
                  const brandRef = firebase.database().ref(`brands/${brandId}`);

                  brandRef.child('products').once('value', (snapshot) => {
                    const products = snapshot.val();
                    delete products[productId];
                    brandRef
                      .update({
                        products
                      })
                      .then(() => {
                        firebase
                          .database()
                          .ref(`products/${productId}`)
                          .remove()
                          .then(() => window.alert('刪除成功'));
                      });
                  });
                }}
                editProduct={() => {
                  setShowProductForm(true);
                  setEditingProductId(productId);
                }}
                showButtons={userId && userId === userInfo.id}
              />
            </div>
          ))}
        {isMine && (
          <div
            className={styles.newProduct}
            onClick={() => {
              setShowProductForm(true);
            }}
          >
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div className={styles.text}>上架商品</div>
          </div>
        )}
      </div>
      {filteredPosts.length > 0 && (
        <div className={styles.videos}>
          <div className={styles.subtitle}>已發佈作品</div>
          <div className={styles.posts}>
            <div className={styles.posts1}>
              {filteredPosts
                .filter((_, index) => (columns === 2 ? index % 2 === 0 : true))
                .map((postId) => {
                  return (
                    <Post
                      key={postId}
                      postId={postId}
                      post={posts[postId]}
                      isDeleteDisabled
                    />
                  );
                })}
            </div>
            {columns === 2 && (
              <div className={styles.posts2}>
                {filteredPosts
                  .filter((_, index) => index % 2 === 1)
                  .map((postId) => {
                    return (
                      <Post
                        key={postId}
                        postId={postId}
                        post={posts[postId]}
                        isDeleteDisabled
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}
      {showGetItem && (
        <GetItem
          productId={productId}
          brandId={brandId}
          closeModal={() => {
            setShowGetItem(false);
          }}
          onSkip={() => {
            history.push(`/brands/${brandId}/products/${productId}`);
          }}
        />
      )}
      {showProductForm && (
        <ProductForm
          productId={editingProductId}
          brandId={brandId}
          editingProduct={editingProduct}
          closeModal={() => {
            setShowProductForm(false);
          }}
        />
      )}
    </div>
  );
};

export default React.memo(Brand);
