import { SHOW_GUIDE, HIDE_GUIDE } from '../actions/guide';

export default (state = {
  show: false
}, action) => {
  switch (action.type) {
    case SHOW_GUIDE:
      return {
        show: true
      };
    case HIDE_GUIDE:
      return {
        show: false
      };
    default:
      return state;
  }
};